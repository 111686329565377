import Vue from "vue";
import Vuex from "vuex";
import invoice from "./invoice";
import device from "./device";
import setup from "./setup";
import config from "./config";

Vue.use( Vuex );

export default new Vuex.Store( {
	strict: process.env.NODE_ENV !== "production",
	state: {
		pdf: {
			renderer: null,
			url: null,
			preview: localStorage.pdfPreview == null ? true : Boolean( parseInt( localStorage.pdfPreview ) ),
		},
		vertical: Boolean( parseInt( localStorage.verticalLayout ) ),
		globalError: null,
	},
	mutations: {
		switchOrientation( state, vertical ) {
			state.vertical = Boolean( vertical ); // eslint-disable-line no-param-reassign
			localStorage.verticalLayout = vertical ? 1 : 0;
		},
		switchPreview( state, showPreview ) {
			state.pdf.preview = Boolean( showPreview ); // eslint-disable-line no-param-reassign
			localStorage.pdfPreview = showPreview ? 1 : 0;
		},
		changeRenderer( state, renderer ) {
			if ( typeof renderer === "function" ) {
				state.pdf.renderer = renderer; // eslint-disable-line no-param-reassign
			}
		},
		changePdf( state, pdfUrl ) {
			if ( state.pdf.url ) {
				window.URL.revokeObjectURL( state.pdf.url );
			}

			state.pdf.url = pdfUrl; // eslint-disable-line no-param-reassign
		},
		clearGlobalError( state ) {
			state.globalError = undefined; // eslint-disable-line no-param-reassign
		},
		setGlobalError( state, error ) {
			if ( error instanceof Error ) {
				state.globalError = error.message; // eslint-disable-line no-param-reassign
			} else if ( error && typeof error === "string" ) {
				state.globalError = error; // eslint-disable-line no-param-reassign
			}
		},
	},
	actions: {
		switchOrientation( { commit }, vertical ) {
			commit( "switchOrientation", vertical );
		},
		switchPreview( { commit }, show ) {
			commit( "switchPreview", show );
		},
		async rerenderPdf( { commit, dispatch, state } ) {
			if ( typeof state.pdf.renderer === "function" ) {
				commit( "changePdf", await state.pdf.renderer( state, dispatch ) );
			}
		},
		async downloadPdf( { dispatch, state } ) {
			if ( typeof state.pdf.renderer === "function" ) {
				const url = await state.pdf.renderer( state, dispatch );

				const a = document.createElement( "a" );
				a.download = `${state.invoice.invoiceId || "invoice"}.pdf`;
				a.href = url;

				a.click();

				setTimeout( () => {
					window.URL.revokeObjectURL( url );
				}, 100 );
			}

			return Promise.resolve();
		},
		renderPdf( { commit, dispatch }, { renderer } ) {
			commit( "changeRenderer", renderer );

			dispatch( "rerenderPdf" );
		},
		updated( { state, dispatch } ) {
			return Promise.resolve( state._deferredProcessor )
				.finally( () => {
					state._deferredProcessor = null; // eslint-disable-line no-param-reassign
				} )
				.then( () => {
					clearTimeout( state._delay );
					state._delay = setTimeout( () => { // eslint-disable-line no-param-reassign
						state._deferredProcessor = dispatch( "rerenderPdf" ); // eslint-disable-line no-param-reassign
					}, 500 );
				} );
		},
		clearGlobalError( { commit } ) {
			commit( "clearGlobalError" );
		},
		setGlobalError( { commit }, error ) {
			commit( "setGlobalError", error );
		},
	},
	modules: {
		invoice, device, setup, config,
	},
} );
