<template>
	<select
		ref="control"
		:disabled="disabled"
		:required="Boolean( placeholder )"
		:style="size ? {width:size} : undefined"
		@input="$emit( 'input', $event.target.value )"
	>
		<option
			v-if="label"
			value=""
			disabled
			:selected="!value"
		>{{label}}</option>
		<option
			v-for="(item, index) in options"
			:class="{promoted: item.promoted, demoted: hasPromoted && !item.promoted}"
			:selected="String( value ) === String( ( item.value || item ) )"
			:key="index"
			:value="String( item.value != null ? item.value : item)"
		>{{ item.label || item }}</option>
	</select>
</template>

<script>
export default {
	name: "OptionsList",
	props: {
		options: Array,
		value: String,
		placeholder: String,
		disabled: Boolean,
		size: String,
	},
	methods: {
		focus() {
			return this.$refs.control.focus();
		},
	},
	watch: {
		options() {
			if ( this.options && this.options.length > 0 ) {
				if ( !this.placeholder && !this.value ) {
					const first = this.options[0];

					this.$emit( "input", String( first?.value || first ) );
				}
			}
		},
	},
	computed: {
		label() {
			return this.value == null ? this.placeholder : undefined;
		},
		hasPromoted() {
			for ( let i = 0; i < this.options?.length; i++ ) {
				if ( this.options[i]?.promoted ) {
					return true;
				}
			}

			return false;
		},
	},
};
</script>

<style scoped lang="scss">
select {
	padding: 0.2rem 0.5rem calc(0.2rem - var(--touch-frame-width)) 0.5rem;
	display: block;
	appearance: none;
	outline: 0;
	margin: 0;
	border-radius: 0;
	border: 0;
	border-bottom: var(--touch-frame-width) solid transparent;
	font: inherit;
	background: var(--touch-background-active);
	color: var(--touch-text-active);
	position: relative;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	line-height: 2em;
	max-width: 100%;

	option {
		background: var(--view-background);
		color: var(--touch-text-active);

		&[value=""][disabled] {
			display: none;
		}

		&.demoted {
			color: var(--touch-text-inactive);
		}
	}

	&:required:invalid {
		color: var(--touch-text-inactive);
	}

	&:not(:disabled) {
		cursor: pointer;
	}

	&:disabled {
		background: var(--touch-background-inactive);
		color: var(--touch-text-inactive);
	}

	&:focus {
		background: var(--touch-background-focused);
		border-bottom-color: var(--brand-color);
		outline: 0;
	}
}
</style>
