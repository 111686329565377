<script>
import OptionsPicker from "../2nd-level/OptionsPicker.vue";

export const taxes = [
	{ value: 19, label: "zzgl. 19% MwSt." },
	{ value: 16, label: "zzgl. 16% MwSt." },
	{ value: 7, label: "zzgl. 7% MwSt." },
	{ value: 5, label: "zzgl. 5% MwSt." },
	{ value: -19, label: "inkl. 19% MwSt." },
	{ value: -16, label: "inkl. 16% MwSt." },
	{ value: -7, label: "inkl. 7% MwSt." },
	{ value: -5, label: "inkl. 5% MwSt." },
	{ value: 0, label: "ohne MwSt." },
];

export default {
	name: "TaxSelector",
	components: { OptionsPicker },
	data() {
		return {
			taxes: taxes.map( tax => ( { ...tax } ) ),
		};
	},
	props: {
		prompt: String,
		value: [ String, Number ],
		size: String,
		disabled: Boolean,
	},
};
</script>

<template>
	<OptionsPicker
		prompt="Steuer"
		:options="taxes"
		:value="value"
		:size="size"
		:disabled="disabled"
		@input="$emit( 'input', $event )"
	/>
</template>

<style scoped lang="scss">

</style>
