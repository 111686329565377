<script>
import { mapActions, mapGetters } from "vuex";
import ToggleSwitch from "../form/1st-level/ToggleSwitch.vue";
import PriceInput from "../form/3rd-level/PriceInput.vue";
import DiscountInput from "../form/3rd-level/DiscountInput.vue";
import TaxSelector from "../form/3rd-level/TaxSelector.vue";
import UnitSelector from "../form/3rd-level/UnitSelector.vue";
import NumericInput from "../form/3rd-level/NumericInput.vue";

export default {
	name: "ColumnsEditor",
	data() {
		return {
			availableColumns: [
				[ "description", "Beschreibung" ],
				[ "date", "Datum" ],
				[ "amount", "Menge", NumericInput ],
				[ "unit", "Einheit", UnitSelector ],
				[ "price", "Einzelpreis", PriceInput ],
				[ "discount", "Rabatt", DiscountInput ],
				[ "tax", "Steueranteil", TaxSelector ],
			]
		};
	},
	components: { ToggleSwitch },
	computed: {
		...mapGetters( {
			columns: "config/columns",
			defaults: "config/defaults",
		} ),
	},
	methods: {
		...mapActions( {
			show: "config/showColumn",
			hide: "config/hideColumn",
			setDefault: "config/setDefault",
		} ),
		toggle( column, show ) {
			if ( show ) {
				this.show( column );
			} else if ( this.columns[column] && Object.keys( this.columns ).length > 1 ) {
				this.hide( column );
			}
		},
	}
};
</script>

<template>
	<div class="columns">
		<div>
			<label>Spalte</label>
			<label>Standard</label>
			<label>sichtbar</label>
		</div>
		<div v-for="[ name, label, editor ] of availableColumns" :key="name">
			<label>{{ label }}</label>
			<span>
				<component
					v-if="editor"
					:is="editor"
					:value="defaults[name]"
					@input="setDefault( { column: name, value: $event } )"
					@change="setDefault( { column: name, value: $event } )"
				/>
			</span>
			<ToggleSwitch :value="columns[name]" @change="toggle(name, $event)" />
		</div>
	</div>
</template>

<style scoped lang="scss">
.columns {
	display: flex;
	flex-flow: column nowrap;
	align-items: stretch;
	gap: 1em;
	min-width: 20em;

	> div {
		display: grid;
		min-width: 100%;
		grid-template-columns: 1fr 1fr 50px;
		gap: 1em;
		line-height: 2.5;
		align-items: center;

		&:first-child {
			font-size: 0.7em;
		}

		> *:last-child {
			justify-self: end;
		}
	}
}
</style>
