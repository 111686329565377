<script>
import OptionsPicker from "../2nd-level/OptionsPicker.vue";

/**
 * Marks items by value and their inner sorting order to be provided atop all
 * other items.
 *
 * @type {Object<string,number>}
 */
const special = {
	58: 1, 59: 2, 30: 3, 31: 4,
};

export default {
	name: "PaymentMeansTypeSelector",
	components: { OptionsPicker },
	data() {
		return {
			options: [],
		};
	},
	props: {
		disabled: Boolean,
		label: String,
		prompt: String,
		size: String,
		value: [ Number, String ],
		limitSize: Boolean,
	},
	async mounted() {
		const { daten: items } = await import( "../../../assets/lists/untdid-4461-payment-means-codes.json" );

		this.options = items
			.map( ( [ value, label ] ) => ( { value, label, promoted: special[value] } ) )
			.sort( ( l, r ) => {
				if ( Boolean( l.promoted ) !== Boolean( r.promoted ) ) {
					return l.promoted ? -1 : 1;
				}

				return ( l.promoted ? l.promoted - r.promoted : 0 ) || l.label.localeCompare( r.label );
			} );
	}
};
</script>

<template>
	<OptionsPicker
		:label="label"
		:limit-size="limitSize"
		:prompt="prompt"
		:options="options"
		:value="value"
		:size="size"
		:disabled="disabled"
		@input="$emit( 'input', Number( $event ) )"
	/>
</template>

<style scoped lang="scss">

</style>
