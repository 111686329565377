<script>
import { parseBoolean } from "../../../lib/data";

export default {
	name: "ToggleSwitch",
	props: {
		value: [ String, Boolean, Number ],
	},
	computed: {
		active() {
			return parseBoolean( this.value );
		},
	},
	methods: {
		toggle() {
			this.$emit( "change", !this.active );
		}
	}
};
</script>

<template>
	<span v-if="active" class="toggle on" @click="toggle()">
		<span><span><fa :icon="['fas', 'check']" /></span><span></span></span>
	</span>
	<span v-else class="toggle off" @click="toggle()">
		<span><span><fa :icon="['fas', 'x']" /></span><span></span></span>
	</span>
</template>

<style scoped lang="scss">
.toggle {
	position: relative;
	display: inline-flex;
	align-items: center;

	> span {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: 1px solid white;
		min-width: 3em;
		height: var(--line-height, 1.3em);
		border-radius: calc( var(--line-height, 1.3em) / 2 );
		cursor: pointer;
		box-shadow: 0 0 1px 1px #0004;

		> span {
			display: flex;
			align-items: center;
			justify-content: center;
			width: calc( 50% - 3px );
			color: white;

			+ span {
				display: flex;
				justify-content: center;
				align-items: center;
				width: calc( var(--line-height, 1.3em) - 6px );
				height: calc( var(--line-height, 1.3em) - 6px );
				border-radius: calc( ( var(--line-height, 1.3em) - 6px ) / 2 );
				background: white;
				margin: 0 3px;
			}
		}
	}

	&.off {
		> span {
			flex-flow: row-reverse;
			background: darkred;

			> span {
				margin-right: 3px;
			}
		}
	}

	&.on {
		> span {
			background: darkgreen;

			> span {
				margin-left: 3px;
			}
		}
	}
}
</style>
