<template>
	<div class="field" :class="{disabled, labelled: label, 'limit-size': limitSize}">
		<label v-if="label">{{ label }}:</label>
		<OptionsList
			:disabled="disabled"
			:options="options"
			:placeholder="prompt"
			:size="size"
			:value="String( current ?? '' )"
			@input="pick"
		></OptionsList>
	</div>
</template>

<script>
import OptionsList from "../1st-level/OptionsList";

export default {
	name: "OptionsPicker",
	components: { OptionsList },
	props: {
		disabled: Boolean,
		label: String,
		limitSize: Boolean,
		options: {
			type: Array,
			validator( value ) {
				return value.every( item => item.hasOwnProperty( "value" ) && item.label );
			},
		},
		prompt: String,
		size: String,
		value: [ String, Number ],
	},
	data() {
		return {
			current: this.value,
		};
	},
	methods: {
		pick( value ) {
			this.current = value;

			this.$emit( "input", value );
		},
	},
};
</script>

<style scoped lang="scss">
.field {
	position: relative;
	display: block;

	&.disabled {
		visibility: hidden;
	}

	&.labelled {
		margin-top: 0.7em;

		> label {
			position: absolute;
			bottom: 100%;
			left: 0;
			font-size: 0.7em;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 100%;
		}
	}

	&.limit-size :deep(select) {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}
</style>
