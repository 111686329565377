<script>
export default {
	name: "FlexFields"
};
</script>

<template>
	<div class="flex-fields">
		<slot></slot>
	</div>
</template>

<style scoped lang="scss">
.flex-fields {
	display: flex;
	flex-flow: row wrap;
	gap: var(--fields-gap, 1rem);
	align-items: stretch;
	justify-content: stretch;

	> .w100 {
		flex: 1 1 100%;
	}

	> .w75 {
		flex: 1 1 75%;
	}

	> .w67 {
		flex: 1 1 66%;
	}

	> .w50 {
		flex: 1 1 calc( 50% - var(--fields-gap, 1rem) );
	}

	> .w40 {
		flex: 1 1 calc( 40% - var(--fields-gap, 1rem) );
	}

	> .w33 {
		flex: 1 1 calc( 33% - var(--fields-gap, 1rem) );
	}

	> .w25 {
		flex: 1 1 calc( 25% - var(--fields-gap, 1rem) );
	}

	> .w20 {
		flex: 1 1 calc( 20% - var(--fields-gap, 1rem) );
	}

	> hr {
		appearance: none;
		border: 0;
		flex: 0 0 100%;
		height: 0;

		margin-top: calc( 0 - var(--fields-gap, 1rem) );

		&.labelled {
			margin-bottom: -0.7em;
		}
	}
}
</style>
