<script>
export default {
	name: "PopOver",
	methods: {
		cancel() {
			this.$emit( "cancel-popup" );
		}
	}
};
</script>

<template>
	<div class="backdrop" @click.prevent.stop="cancel">
		<div class="popover" @click.prevent.stop>
			<div class="header" v-if="$slots.header">
				<slot name="header"></slot>
			</div>
			<div class="body">
				<slot></slot>
			</div>
			<div class="footer" v-if="$slots.footer">
				<slot name="footer"></slot>
			</div>
			<div class="cancel" @click="cancel"><fa :icon="['fas', 'x']" /></div>
		</div>
	</div>
</template>

<style scoped lang="scss">
.backdrop {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	backdrop-filter: blur(2px);
	background: #0002;
	display: flex;
	align-items: center;
	justify-content: center;
}

.popover {
	display: flex;
	flex-flow: column nowrap;
	min-width: var(--popover-min-width, 300px);
	max-width: var(--popover-max-width, calc( 100% - 40px ) );
	background: white;
	border-radius: 3px;
	box-shadow: 0 0 2px #0006;
	box-sizing: border-box;
	position: relative;
	overflow: auto;

	> .cancel {
		position: absolute;
		top: 0;
		right: 0;
		width: 2em;
		line-height: 2em;
		text-align: center;
		cursor: pointer;
		opacity: 0.3;

		&:hover {
			opacity: 1.0;
		}
	}

	.header {
		position: sticky;
		top: 0;
		left: 0;
		right: 0;
		padding: 2em 2em 0;
	}

	.body {
		padding: 2em;
	}

	.footer {
		position: sticky;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 0 2em 2em;
	}
}
</style>
