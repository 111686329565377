<template>
	<input
		:placeholder="placeholder" :disabled="disabled" ref="input"
		:value="value ?? ''" @input="$emit( 'input', $event.target.value )"
		:type="concealed ? 'password' : type"
		@keydown.exact.prevent.up="adjust(1, 1)"
		@keydown.exact.prevent.down="adjust(-1, -1)"
		@keydown.exact.prevent.shift.up="adjust(10, 2)"
		@keydown.exact.prevent.shift.down="adjust(-10, -2)"
		@keydown.exact.prevent.shift.ctrl.up="adjust(100, 3)"
		@keydown.exact.prevent.shift.ctrl.down="adjust(-100, -3)"
	>
</template>

<script>
import { adjustNumeric } from "../../../lib/data";

export default {
	name: "TextInput",
	props: {
		value: [ String, Number ],
		placeholder: String,
		concealed: Boolean,
		disabled: Boolean,
		type: {
			type: String,
			default: "text",
		},
	},
	methods: {
		focus() {
			this.$refs.input.focus();
		},
		adjust( amount, mode ) {
			if ( this.type !== "date" ) {
				const adjusted = adjustNumeric( this.value, amount, mode );

				if ( adjusted !== this.value ) {
					this.$emit( "input", typeof adjusted === "number" ? new Intl.NumberFormat().format( adjusted ) : adjusted );
				}
			}
		}
	},
};
</script>

<style scoped lang="scss">
input {
	display: block;
	appearance: none;
	padding: 0.2rem 0.5rem 0 0.5rem;
	margin: 0;
	outline: 0;
	border: 0;
	border-bottom: var(--touch-frame-width) solid transparent;
	border-radius: 0;
	font: inherit;
	font-size: 1rem;
	line-height: 2rem;
	height: 2.4rem;
	box-shadow: none;
	box-sizing: border-box;
	background: var(--touch-background-active);
	color: var(--touch-text-active);

	&::placeholder {
		color: var(--touch-text-inactive);
	}

	&:not(:disabled) {
		cursor: text;
	}

	&:disabled {
		background: var(--touch-background-inactive);
		color: var(--touch-text-inactive);
	}

	&:focus {
		background: var(--touch-background-focused);
		border-bottom-color: var(--brand-color);
		outline: 0;
	}
}
</style>
