const DefaultColumns = [ "description", "amount", "unit", "price", "discount", "tax" ];

export default {
	namespaced: true,
	state: {
		_storage: null,
		columns: [...DefaultColumns],
		defaults: {},
		headEditor: "extended",
	},
	getters: {
		hasStorage: state => state._storage != null,
		columns: state => {
			const map = {};

			for ( const column of state.columns ) {
				map[column] = true;
			}

			return map;
		},
		defaults: state => state.defaults || {},
	},
	mutations: {
		useStorage( state, storage ) {
			state._storage = storage; // eslint-disable-line no-param-reassign
		},

		restore( state, dump ) {
			if ( state._storage != null ) {
				let record;

				try {
					record = typeof dump === "string" ? JSON.parse( dump ) : typeof dump === "object" && dump ? dump : {};
				} catch {} // eslint-disable-line no-empty

				state.columns = record?.columns?.length > 0 ? record.columns : [...DefaultColumns];
				state.defaults = record?.defaults || {};
				state.headEditor = record?.headEditor || "extended";
			}
		},
		sync( state ) {
			if ( state._storage != null ) {
				const dump = JSON.stringify( {
					columns: state.columns,
					defaults: state.defaults,
					headEditor: state.headEditor,
				} );

				if ( typeof state._storage.setItem === "function" ) {
					state._storage.setItem( "config", dump );
				} else {
					state._storage.config = dump;
				}
			}
		},

		reset( state ) {
			state.columns = [...DefaultColumns];
			state.defaults = {};
		},
		showColumn( state, column ) {
			if ( !state.columns.includes( column ) ) {
				state.columns.push( column );
			}
		},
		hideColumn( state, column ) {
			const index = state.columns.indexOf( column );
			if ( index > -1 ) {
				state.columns.splice( index, 1 );
			}
		},
		setDefault( state, { column, value } ) {
			state.defaults[column] = value;
		},

		setHeadEditor( state, name ) {
			state.headEditor = name;
		},
	},
	actions: {
		useStorage( { commit, dispatch }, storage ) {
			commit( "useStorage", storage );
			commit( "restore", typeof storage.getItem === "function" ? storage.getItem( "config" ) : storage.config );
			dispatch( "updated", null, { root: true } );
		},
		reset( { commit, dispatch } ) {
			commit( "reset" );
			commit( "sync" );
			dispatch( "updated", null, { root: true } );
		},
		showColumn( { commit, dispatch }, column ) {
			commit( "showColumn", column );
			commit( "sync" );
			dispatch( "updated", null, { root: true } );
		},
		hideColumn( { commit, dispatch }, column ) {
			commit( "hideColumn", column );
			commit( "sync" );
			dispatch( "updated", null, { root: true } );
		},
		setDefault( { commit, dispatch }, { column, value } ) {
			commit( "setDefault", { column, value } );
			commit( "sync" );
			dispatch( "updated", null, { root: true } );
		},
		setHeadEditor( { commit }, name ) {
			commit( "setHeadEditor", name );
			commit( "sync" );
		},
		backup( { state } ) {
			return state._storage ? {
				columns: state.columns,
				defaults: state.defaults,
				headEditor: state.headEditor,
			} : null;
		},
		restore( { commit, dispatch }, dump ) {
			if ( dump ) {
				commit( "restore", dump );
				dispatch( "updated", null, { root: true } );
			}
		},
	},
	modules: {
	},
};
