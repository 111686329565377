<script>
import NumericInput from "./NumericInput.vue";

export default {
	name: "PriceInput",
	components: { NumericInput },
	props: {
		value: [ String, Number ],
		pre: String,
		prompt: String,
		post: {
			type: String,
			default: "€",
		},
		size: String,
		disabled: Boolean,
	},
};
</script>

<template>
  <NumericInput
      :size="size"
      :prompt="prompt"
      :value="value"
      :pre="pre"
      :post="post"
      :disabled="disabled"
      @input="$emit('input', $event)"
  />
</template>

<style scoped lang="scss">

</style>

