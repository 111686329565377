import PdfMake from "pdfmake/build/pdfmake";
import { defaults } from "./defaults";
import * as utils from "../../lib/data";
import { renderXml } from "../../lib/x-rechnung";
import { encode, round, toBase64 } from "../../lib/data";

// import PdfFonts from "../../assets/myriad-pro-vfs-fonts";
// PdfMake.vfs = PdfFonts.pdfMake.vfs;
PdfMake.fonts = {
/*
	myriad: {
		normal: "MyriadPro-Light.otf",
		italics: "MyriadPro-LightIt.otf",
		bold: "MyriadPro-Semibold.otf",
		bolditalics: "MyriadPro-SemiboldIt.otf",
	},
*/
};

/**
 * Implements renderer for invoice PDF documents.
 *
 * @param {object} state current root state of application
 * @param {function(string, ...any):Promise<any>} dispatch callback dispatching action on state of application
 * @returns {string} URL of PDF document to render
 */
export default async function invoiceRenderer( state, dispatch ) {
	const cm = 72 / 2.54;


	// create a safe copy so no code is accessing original invoice data in state
	const invoice = {};

	for ( const name of Object.keys( state.invoice ) ) {
		if ( !name.startsWith( "_" ) && name !== "saved" ) {
			const value = state.invoice[name];

			if ( Array.isArray( value ) ) {
				invoice[name] = value.map( item => Object.assign( {}, item ) );
			} else {
				invoice[name] = value instanceof Date ? new Date( value ) : typeof value === "object" && value ? Object.assign( {}, value ) : value;
			}
		}
	}


	// collect sums per tax level
	const sums = {
		19: { index: 1, net: 0, gross: 0, tax: 0 },
		16: { index: 3, net: 0, gross: 0, tax: 0 },
		7: { index: 2, net: 0, gross: 0, tax: 0 },
		5: { index: 4, net: 0, gross: 0, tax: 0 },
		0: { index: 5, net: 0, gross: 0, tax: 0 },
	};


	// qualify items, cumulate sums
	const items = invoice.items.map( item => {
		const copy = Object.assign( {}, item );

		if ( copy.unit === "" ) {
			copy.total = copy.quantity = copy.amount = copy.unitNet = copy.unitGross = copy.unitDiscount = copy.discount = 0;
		} else {
			copy.discount = utils.parseNumber( copy.discount ) || 0;

			const taxMode = parseFloat( String( copy.tax ) );
			const taxLevel = Math.abs( taxMode );
			const taxScale = ( 100 + taxLevel ) / 100;

			if ( isNaN( taxScale ) ) {
				return null;
			}

			const quantity = copy.quantity = copy.amount = copy.unit === "-" ? 1 : utils.parseNumber( copy.amount );
			const unitPrice = utils.parseNumber( copy.price );
			let gross = taxMode < 0 ? unitPrice / taxScale : unitPrice;

			if ( taxMode < 0 && round( round( gross, 2 ) * taxScale, 2 ) > unitPrice ) {
				gross -= 0.01;
			}

			const discount = ( 100 - copy.discount ) / 100;

			copy.unitGross = gross; 							// BT-148, amount per unit w/o item-specific discounts and VAT
			copy.unitNet = copy.unitGross * discount;			// BT-146, amount per unit w/ item-specific discounts but w/o VAT
			copy.unitDiscount = copy.unitGross - copy.unitNet;	// BT-147, amount per unit of item-specific discount

			copy.total = round( copy.unitNet * quantity, 2 );	// BT-131, amount of invoice line w/ item-specific discounts, but w/o VAT

			sums[taxLevel].net += copy.total;
		}

		// make sure some now deprecated properties are still available
		copy.price = copy.unitGross;
		copy.sum = copy.total;

		copy.date ??= undefined; // make sure key "date" is covered when interpolating below

		copy.description = utils.interpolate( copy.description, ( key, _, optionsFn ) => {
			for ( const candidate of Object.keys( copy ) ) {
				if ( candidate.toLowerCase() === key ) {
					return optionsFn( copy[key] ?? "" );
				}
			}

			return undefined;
		} );

		return copy;
	} );


	const totals = Object.keys( sums )
		.filter( level => sums[level].net > 0 )
		.map( level => {
			const taxLevel = parseFloat( level );
			const copy = { ...sums[level], taxLevel };

			copy.net = round( copy.net, 2 );
			copy.tax = round( copy.net * taxLevel / 100 );
			copy.gross = copy.net + copy.tax;

			return copy;
		} )
		.sort( ( l, r ) => l.index - r.index );


	// process qualified data to all stages for generating document description
	let exports = {};
	runHandler( state.setup, "context",
		[ "exports", "utils", "cm", "invoice", "items", "sums", "totals" ],
		[ exports, utils, cm, invoice, items, sums, totals ] );
	const context = exports.context || {};

	exports = {};
	runHandler( state.setup, "table",
		[ "exports", "utils", "cm", "invoice", "items", "sums", "totals", "context" ],
		[ exports, utils, cm, invoice, items, sums, totals, context ] );
	const table = exports.table || {};

	exports = {};
	runHandler( state.setup, "summary",
		[ "exports", "utils", "cm", "invoice", "items", "sums", "totals", "context", "table" ],
		[ exports, utils, cm, invoice, items, sums, totals, context, table ] );
	const summary = exports.summary || {};

	exports = {};
	runHandler( state.setup, "document",
		[ "exports", "utils", "cm", "invoice", "items", "sums", "totals", "context", "table", "summary" ],
		[ exports, utils, cm, invoice, items, sums, totals, context, table, summary ] );
	const document = exports.document || {};
	let xmlFilename = "xrechnung.xml";

	// compile XML attachment describing invoice for automatic processing
	try {
		const xml = renderXml( state.invoice, items, totals, context );

		document.files ??= {};
		document.files.xrechnung = {
			name: xmlFilename,
			src: "data:application/xml;charset=utf-8;base64," + toBase64( encode( xml ) ),
		};

		dispatch( "clearGlobalError" );
	} catch ( cause ) {
		dispatch( "setGlobalError", "XRechnung nicht möglich: " + cause.message );
		xmlFilename = false;

		console.error( "rendering XRechnung failed: " + cause.message );
	}


	await Promise.all( [
		interpolateDeep( document.header, dispatch ),
		interpolateDeep( document.content, dispatch ),
		interpolateDeep( document.footer, dispatch ),
	] );

	if ( context.fonts ) {
		// FIXME remove in case https://github.com/bpampuch/pdfmake/issues/2828 is fixed
		PdfMake.urlResolver.resolving = {};
		PdfMake.addFonts( context.fonts );
	}

	try {
		// hacky: access PDFKit wrapped in pdfmake to embed custom XMP metadata
		const pdf = PdfMake.createPdf( document );
		const pdfKit = await pdf.getStream();

		if ( xmlFilename ) {
			// XML extracted from file
			//
			// 2. FACTUR-X_1.0.07.2_extension_schema_example.xmp.txt
			//
			// included with ZugFeRD specification fetched from
			//
			// https://www.ferd-net.de/download-zugferd
			//
			// in folder Dokumentation
			pdfKit.appendXML( `   <rdf:Description xmlns:pdfaExtension="http://www.aiim.org/pdfa/ns/extension/" xmlns:pdfaSchema="http://www.aiim.org/pdfa/ns/schema#" xmlns:pdfaProperty="http://www.aiim.org/pdfa/ns/property#" rdf:about="">
      <pdfaExtension:schemas>
        <rdf:Bag>
          <rdf:li rdf:parseType="Resource">
            <pdfaSchema:schema>Factur-X PDFA Extension Schema</pdfaSchema:schema>
            <pdfaSchema:namespaceURI>urn:factur-x:pdfa:CrossIndustryDocument:invoice:1p0#</pdfaSchema:namespaceURI>
            <pdfaSchema:prefix>fx</pdfaSchema:prefix>
            <pdfaSchema:property>
              <rdf:Seq>
                <rdf:li rdf:parseType="Resource">
                  <pdfaProperty:name>DocumentFileName</pdfaProperty:name>
                  <pdfaProperty:valueType>Text</pdfaProperty:valueType>
                  <pdfaProperty:category>external</pdfaProperty:category>
                  <pdfaProperty:description>The name of the embedded XML document</pdfaProperty:description>
                </rdf:li>
                <rdf:li rdf:parseType="Resource">
                  <pdfaProperty:name>DocumentType</pdfaProperty:name>
                  <pdfaProperty:valueType>Text</pdfaProperty:valueType>
                  <pdfaProperty:category>external</pdfaProperty:category>
                  <pdfaProperty:description>The type of the hybrid document in capital letters, e.g. INVOICE or ORDER</pdfaProperty:description>
                </rdf:li>
                <rdf:li rdf:parseType="Resource">
                  <pdfaProperty:name>Version</pdfaProperty:name>
                  <pdfaProperty:valueType>Text</pdfaProperty:valueType>
                  <pdfaProperty:category>external</pdfaProperty:category>
                  <pdfaProperty:description>The actual version of the standard applying to the embedded XML document</pdfaProperty:description>
                </rdf:li>
                <rdf:li rdf:parseType="Resource">
                  <pdfaProperty:name>ConformanceLevel</pdfaProperty:name>
                  <pdfaProperty:valueType>Text</pdfaProperty:valueType>
                  <pdfaProperty:category>external</pdfaProperty:category>
                  <pdfaProperty:description>The conformance level of the embedded XML document</pdfaProperty:description>
                </rdf:li>
              </rdf:Seq>
            </pdfaSchema:property>
          </rdf:li>
        </rdf:Bag>
      </pdfaExtension:schemas>
    </rdf:Description>
    <rdf:Description xmlns:fx="urn:factur-x:pdfa:CrossIndustryDocument:invoice:1p0#" rdf:about="">
      <fx:DocumentType>INVOICE</fx:DocumentType>
      <fx:DocumentFileName>${xmlFilename}</fx:DocumentFileName>
      <fx:Version>1.0</fx:Version>
      <fx:ConformanceLevel>EN 16931</fx:ConformanceLevel>
    </rdf:Description>` );
		}

		return pdf.getDataUrl();
	} catch {
		// fallback: use official pdfmake API to generate PDF with limited ZugFeRD/XMP validity, only
		return await PdfMake.createPdf( document ).getDataUrl();
	}
}

/**
 * Invokes custom handler for named stage available in setup falling back to use
 * related default handler if custom code is missing.
 *
 * @param {Object<string,string>} setup map of stage names into custom code
 * @param {string} name name of stage to run
 * @param {string[]} parameters names of parameters exposed to stage's code
 * @param {any[]} args arguments for running stage's code
 * @returns {void}
 */
function runHandler( setup, name, parameters, args ) {
	if ( setup[name] ) {
		new Function( ...parameters, setup[name] )( ...args );
	} else if ( defaults[name] ) {
		defaults[name]( ...args );
	}
}

/**
 * Recursively interpolates deeply contained strings in a provided object or
 * array of objects.
 *
 * @param {object} content hierarchy of data to interpolate, gets updated in-place
 * @param {function(string, ...any):Promise<any>} dispatch callback dispatching action on state of application
 * @returns {Promise<void>} promise settled when done
 */
async function interpolateDeep( content, dispatch ) {
	if ( Array.isArray( content ) ) {
		for ( let i = 0; i < content.length; i++ ) {
			const value = content[i];

			switch ( typeof value ) {
				case "string" :
					content[i] = await dispatch( "invoice/interpolate", value ); // eslint-disable-line no-await-in-loop,no-param-reassign
					break;

				case "object" :
					if ( value ) {
						await interpolateDeep( value, dispatch ); // eslint-disable-line no-await-in-loop,no-param-reassign
					}
			}
		}
	} else if ( content && typeof content === "object" ) {
		for ( const key of Object.keys( content ) ) {
			const value = content[key];

			switch ( typeof value ) {
				case "string" :
					content[key] = await dispatch( "invoice/interpolate", value ); // eslint-disable-line no-await-in-loop,no-param-reassign
					break;

				case "object" :
					if ( value ) {
						await interpolateDeep( value, dispatch ); // eslint-disable-line no-await-in-loop,no-param-reassign
					}
			}
		}
	}
}
