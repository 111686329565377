<script>
import TextField from "../2nd-level/TextField.vue";

export default {
	name: "DiscountInput",
	components: { TextField },
	props: {
		value: [ String, Number ],
		pre: {
			type: String,
			default: "-",
		},
		prompt: {
			type: String,
			default: "",
		},
		post: {
			type: String,
			default: "%",
		},
		size: {
			type: String,
			default: "5rem"
		},
		disabled: Boolean,
	}
};
</script>

<template>
  <TextField
      align="right"
      :size="size"
      :prompt="prompt"
      :value="value"
      :pre="pre"
      :post="post"
      :disabled="disabled"
      @input="$emit('input', $event)"
  ></TextField>
</template>

<style scoped lang="scss">

</style>
