export default {
	namespaced: true,
	state: {
		lean: 0,
		small: 0,
	},
	getters: {
		isLeanDevice: state => state.lean > 0,
		isWideDevice: state => state.lean < 0,
		isSmallDevice: state => state.small > 0,
		isLargeDevice: state => state.small < 0,
	},
	mutations: {
		setLean: state => {
			state.lean = 1; // eslint-disable-line no-param-reassign
		},
		setWide: state => {
			state.lean = -1; // eslint-disable-line no-param-reassign
		},
		setSmall: state => {
			state.small = 1; // eslint-disable-line no-param-reassign
		},
		setLarge: state => {
			state.small = -1; // eslint-disable-line no-param-reassign
		},
	},
};
