import {formatDate, round} from "./data";
import { units } from "../components/form/3rd-level/UnitSelector.vue";

export const DocumentCodeType = new Map( [
	[ 71, "Request for payment" ],
	[ 80, "Debit note related to goods or services" ],
	[ 81, "Credit note related to goods or services" ],
	[ 82, "Metered services invoice" ],
	[ 83, "Credit note related to financial adjustments" ],
	[ 84, "Debit note related to financial adjustments" ],
	[ 102, "Tax notification" ],
	[ 130, "Rechnungsdatenblatt" ],
	[ 202, "Verkürzte Baurechnung" ],
	[ 203, "Vorläufige Baurechnung" ],
	[ 204, "Baurechnung" ],
	[ 211, "Zwischen-(abschlags-)rechnung" ],
	[ 218, "Final payment request based on completion of work" ],
	[ 219, "Payment request for completed units" ],
	[ 261, "Self billed credit note" ],
	[ 262, "Consolidated credit note - goods and services" ],
	[ 295, "Price variation invoice" ],
	[ 296, "Credit note for price variation" ],
	[ 308, "Delcredere credit note" ],
	[ 325, "Proformarechnung" ],
	[ 326, "Teilrechnung" ],
	[ 331, "Commercial invoice which includes a packing list" ],
	[ 380, "Handelsrechnung" ],
	[ 381, "Gutschriftanzeige" ],
	[ 382, "Provisionsmitteilung" ],
	[ 383, "Belastungsanzeige" ],
	[ 384, "Rechnungskorrektur" ],
	[ 385, "Konsolidierte Rechnung" ],
	[ 386, "Vorauszahlungsrechnung" ],
	[ 387, "Mietrechnung" ],
	[ 388, "Steuerrechnung" ],
	[ 389, "Selbst ausgestellte Rechnung (Gutschrift im Gutschriftsverfahren)" ],
	[ 390, "Delkredere-Rechnung" ],
	[ 393, "Inkasso-Rechnung" ],
	[ 394, "Leasing-Rechnung" ],
	[ 395, "Konsignationsrechnung" ],
	[ 396, "Inkasso-Gutschrift" ],
	[ 420, "Optical Character Reading (OCR) payment credit note" ],
	[ 456, "Belastungsanzeige" ],
	[ 457, "Storno einer Belastung." ],
	[ 458, "Storno einer Gutschrift" ],
	[ 527, "Self billed debit note" ],
	[ 532, "Gutschrift des Spediteurs" ],
	[ 553, "Forwarder’s invoice discrepancy report" ],
	[ 575, "Rechnung des Versicherers" ],
	[ 623, "Speditionsrechnung" ],
	[ 633, "Hafenkostenrechnung" ],
	[ 751, "Invoice information for accounting purposes" ],
	[ 780, "Frachtrechnung" ],
	[ 817, "Claim notification" ],
	[ 870, "Konsulatsfaktura" ],
	[ 875, "Partial construction invoice" ],
	[ 876, "Partial final construction invoice" ],
	[ 877, "Final construction invoice" ],
	[ 935, "Zollrechnung" ],
] );

export const AllowanceChargeReasonCodeType = new Map( [
	[ 41, "Bonus for works ahead of schedule" ],
	[ 42, "Other bonus" ],
	[ 60, "Manufacturer's consumer discount" ],
	[ 62, "Due to military status" ],
	[ 63, "Due to work accident" ],
	[ 64, "Special agreement" ],
	[ 65, "Production error discount" ],
	[ 66, "New outlet discount" ],
	[ 67, "Sample discount" ],
	[ 68, "End-of-range discount" ],
	[ 70, "Incoterm discount" ],
	[ 71, "Point of sales threshold allowance" ],
	[ 88, "Material surcharge/deduction" ],
	[ 95, "Discount" ],
	[ 100, "Special rebate" ],
	[ 102, "Fixed long term" ],
	[ 103, "Temporary" ],
	[ 104, "Standard" ],
	[ 105, "Yearly turnover" ],
] );

/**
 * Generates XML describing provided invoice.
 *
 * @param {object} invoice properties of invoice
 * @param {object[]} items list of qualified invoice items
 * @param {object[]} sums cumulated amounts per tax category
 * @param {object} context additional information customizable by user in setup/designer
 * @returns {string} XML-encoded invoice
 */
export function renderXml( invoice, items, sums, context ) {
	if ( !invoice.sellerContactPoint ) {
		throw new Error( "Ein Kontakt für Fragen zur Rechnung ist erforderlich." );
	}

	if ( !invoice.sellerContactTelephoneNumber ) {
		throw new Error( "Zum Kontakt für Fragen zur Rechnung ist eine Telefonnummer erforderlich." );
	}

	if ( !invoice.sellerContactEmailAddress ) {
		throw new Error( "Zum Kontakt für Fragen zur Rechnung ist eine E-Mail-Adresse erforderlich." );
	}

	if ( !invoice.sellerVatIdentifier && !invoice.sellerTaxRegistrationIdentifier ) {
		throw new Error( "Die Steuernummer oder USt-ID des Verkäufers ist erforderlich." );
	}

	if ( !invoice.paymentDueDate ) {
		throw new Error( "Das Datum der Zahlungsfälligkeit fehlt." );
	}

	if ( !invoice.buyerName ) {
		throw new Error( "Der Name des Käufers ist erforderlich." );
	}

	if ( !invoice.buyerCity || !invoice.buyerPostCode ) {
		throw new Error( "Die vollständige Anschrift des Käufers ist erforderlich." );
	}

	if ( !invoice.buyerElectronicAddress ) {
		throw new Error( "Die E-Mail-Adresse des Käufers ist erforderlich." );
	}

	return xml`<?xml version="1.0" encoding="UTF-8" ?>
<rsm:CrossIndustryInvoice xmlns:rsm="urn:un:unece:uncefact:data:standard:CrossIndustryInvoice:100" xmlns:qdt="urn:un:unece:uncefact:data:standard:QualifiedDataType:100" xmlns:ram="urn:un:unece:uncefact:data:standard:ReusableAggregateBusinessInformationEntity:100" xmlns:xs="http://www.w3.org/2001/XMLSchema" xmlns:udt="urn:un:unece:uncefact:data:standard:UnqualifiedDataType:100">
  <rsm:ExchangedDocumentContext>
    <ram:BusinessProcessSpecifiedDocumentContextParameter>
      <ram:ID>urn:fdc:peppol.eu:2017:poacc:billing:01:1.0</ram:ID>
    </ram:BusinessProcessSpecifiedDocumentContextParameter>
    <ram:GuidelineSpecifiedDocumentContextParameter>
      <ram:ID>urn:cen.eu:en16931:2017#compliant#urn:xeinkauf.de:kosit:xrechnung_3.0</ram:ID>
    </ram:GuidelineSpecifiedDocumentContextParameter>
  </rsm:ExchangedDocumentContext>
  <rsm:ExchangedDocument>
    <ram:ID>${invoice.invoiceId}</ram:ID>
    <ram:TypeCode>${invoice.invoiceType}</ram:TypeCode>
    <ram:IssueDateTime>
      <udt:DateTimeString format="102">${formatDate102( invoice.billingDate )}</udt:DateTimeString>
    </ram:IssueDateTime>
  </rsm:ExchangedDocument>
  <rsm:SupplyChainTradeTransaction>
` +
		renderItemsXml( invoice, items, sums, context ) +
		renderBuyerAndSellerXml( invoice, items, sums, context ) +
		xml`    <ram:ApplicableHeaderTradeDelivery>
      <ram:ActualDeliverySupplyChainEvent>
        <ram:OccurrenceDateTime>
          <udt:DateTimeString format="102">${formatDate102( invoice.deliveryDate || invoice.billingDate )}</udt:DateTimeString>
        </ram:OccurrenceDateTime>
      </ram:ActualDeliverySupplyChainEvent>
    </ram:ApplicableHeaderTradeDelivery>
` + renderPaymentXml( invoice, items, sums, context ) + `  </rsm:SupplyChainTradeTransaction>
</rsm:CrossIndustryInvoice>`;
}

/**
 * Renders XML describing items of invoice.
 *
 * @param {object} invoice properties of invoice
 * @param {object[]} items qualified items of invoice
 * @param {object[]} sums cumulated amounts per tax category
 * @param {object} context custom information provided by user in setup code
 * @returns {string} rendered XML
 */
export function renderItemsXml( invoice, items, sums, context ) { // eslint-disable-line no-unused-vars
	const lines = [];
	let lineId = 1;

	for ( const item of items ) {
		if ( !item.amount ) {
			continue;
		}

		const itemLines = item.description.trim().split( /\n/ );
		const itemName = itemLines.shift().trim();
		const itemDescription = itemLines.join( "\n" );
		const itemUnit = units.find( unit => unit.value === item.unit )?.code;

		if ( !itemUnit ) {
			throw new Error( "item unit not supported for XRechnung: " + item.unit );
		}

		const billingDate = item.date ? xml`        <ram:BillingSpecifiedPeriod>
          <ram:StartDateTime>
            <udt:DateTimeString format="102">${formatDate102( item.date )}</udt:DateTimeString>
          </ram:StartDateTime>
          <ram:EndDateTime>
            <udt:DateTimeString format="102">${formatDate102( item.date )}</udt:DateTimeString>
          </ram:EndDateTime>
        </ram:BillingSpecifiedPeriod>
` : "";

		const description = itemDescription.trim() ? xml`        <ram:Description>${itemDescription.trim()}</ram:Description>
` : "";

		const discount = item.discount ? xml`        <ram:GrossPriceProductTradePrice>
          <ram:ChargeAmount>${round( item.unitGross, 4 )}</ram:ChargeAmount>
          <ram:AppliedTradeAllowanceCharge>
            <ram:ChargeIndicator><udt:Indicator>false</udt:Indicator></ram:ChargeIndicator>
            <ram:ActualAmount>${round( item.unitDiscount, 4 )}</ram:ActualAmount>
          </ram:AppliedTradeAllowanceCharge>
        </ram:GrossPriceProductTradePrice>
` : "";

		lines.push( `    <ram:IncludedSupplyChainTradeLineItem>
      <ram:AssociatedDocumentLineDocument>
        <ram:LineID>${lineId++}</ram:LineID>
      </ram:AssociatedDocumentLineDocument>
      <ram:SpecifiedTradeProduct>
        ${xml`<ram:Name>${itemName}</ram:Name>`}
${description}      </ram:SpecifiedTradeProduct>
      <ram:SpecifiedLineTradeAgreement>
${discount}        <ram:NetPriceProductTradePrice>
          ${xml`<ram:ChargeAmount>${round( item.unitNet, 4 )}</ram:ChargeAmount>`}
        </ram:NetPriceProductTradePrice>
      </ram:SpecifiedLineTradeAgreement>
      <ram:SpecifiedLineTradeDelivery>
        ${xml`<ram:BilledQuantity unitCode="${itemUnit}">${round( item.amount, 4 )}</ram:BilledQuantity>`}
      </ram:SpecifiedLineTradeDelivery>
      <ram:SpecifiedLineTradeSettlement>
        <ram:ApplicableTradeTax>
          <ram:TypeCode>VAT</ram:TypeCode>
          <ram:CategoryCode>S</ram:CategoryCode>
          ${xml`<ram:RateApplicablePercent>${Math.abs( parseInt( item.tax ) )}</ram:RateApplicablePercent>`}
        </ram:ApplicableTradeTax>
${billingDate}        <ram:SpecifiedTradeSettlementLineMonetarySummation>
          ${xml`<ram:LineTotalAmount>${round( item.total, 2 )}</ram:LineTotalAmount>`}
        </ram:SpecifiedTradeSettlementLineMonetarySummation>
      </ram:SpecifiedLineTradeSettlement>
    </ram:IncludedSupplyChainTradeLineItem>
` );
	}

	if ( !lines.length ) {
		throw new Error( "invoice without items not supported by XRechnung" );
	}

	return lines.join( "\n" );
}

/**
 * Renders XML describing information on parties involved in the trade.
 *
 * @param {object} invoice properties of invoice
 * @param {object[]} items qualified items of invoice
 * @param {object[]} sums cumulated amounts per tax category
 * @param {object} context custom information provided by user in setup code
 * @returns {string} rendered XML
 */
export function renderBuyerAndSellerXml( invoice, items, sums, context ) { // eslint-disable-line no-unused-vars
	// --- compile information on the seller
	const sellerContactName = invoice.sellerContactPoint ? xml`          <ram:PersonName>${invoice.sellerContactPoint}</ram:PersonName>
` : "";
	const sellerContactMail = invoice.sellerContactEmailAddress ? xml`          <ram:EmailURIUniversalCommunication>
            <ram:URIID>${invoice.sellerContactEmailAddress}</ram:URIID>
          </ram:EmailURIUniversalCommunication>
` : "";
	const sellerContactPhone = invoice.sellerContactTelephoneNumber ? xml`          <ram:TelephoneUniversalCommunication>
            <ram:CompleteNumber>${invoice.sellerContactTelephoneNumber}</ram:CompleteNumber>
          </ram:TelephoneUniversalCommunication>
` : "";
	const sellerContact = sellerContactName || sellerContactMail || sellerContactPhone ? `        <ram:DefinedTradeContact>
${sellerContactName}${sellerContactPhone}${sellerContactMail}        </ram:DefinedTradeContact>
` : "";
	const sellerId = invoice.sellerIdentifier ? xml`          <ram:GlobalID>${invoice.sellerIdentifier}</ram:GlobalID>
` : "";
	const sellerName = xml`<ram:Name>${invoice.sellerName}</ram:Name>`;
	const sellerPostCode = invoice.sellerPostCode ? xml`          <ram:PostcodeCode>${invoice.sellerPostCode}</ram:PostcodeCode>
` : "";
	const sellerAddress1 = invoice.sellerAddress1 ? xml`          <ram:LineOne>${invoice.sellerAddress1}</ram:LineOne>
` : "";
	const sellerAddress2 = invoice.sellerAddress2 ? xml`          <ram:LineTwo>${invoice.sellerAddress2}</ram:LineTwo>
` : "";
	const sellerCity = invoice.sellerCity ? xml`          <ram:CityName>${invoice.sellerCity}</ram:CityName>
` : "";
	const sellerCountry = invoice.sellerCountry ? xml`          <ram:CountryID>${invoice.sellerCountry}</ram:CountryID>
` : "";
	const sellerMail = invoice.sellerElectronicAddress ? xml`        <ram:URIUniversalCommunication>
          <ram:URIID schemeID="EM">${invoice.sellerElectronicAddress}</ram:URIID>
        </ram:URIUniversalCommunication>
` : "";
	const sellerTaxIdentifier = invoice.sellerTaxRegistrationIdentifier ? xml`        <ram:SpecifiedTaxRegistration>
          <ram:ID schemeID="FC">${invoice.sellerTaxRegistrationIdentifier}</ram:ID>
        </ram:SpecifiedTaxRegistration>
` : "";
	const sellerVatIdentifier = invoice.sellerVatIdentifier ? xml`        <ram:SpecifiedTaxRegistration>
          <ram:ID schemeID="VA">${invoice.sellerVatIdentifier}</ram:ID>
        </ram:SpecifiedTaxRegistration>
` : "";

	const seller = `      <ram:SellerTradeParty>
${sellerId}        ${sellerName}
${sellerContact}        <ram:PostalTradeAddress>
${sellerPostCode}${sellerAddress1}${sellerAddress2}${sellerCity}${sellerCountry}        </ram:PostalTradeAddress>
${sellerMail}${sellerTaxIdentifier}${sellerVatIdentifier}      </ram:SellerTradeParty>
`;

	// --- compile information on the buyer
	const buyerId = xml`<ram:ID>${invoice.customerId}</ram:ID>`;
	const buyerName = xml`<ram:Name>${invoice.buyerName}</ram:Name>`;
	const buyerPostCode = invoice.buyerPostCode ? xml`          <ram:PostcodeCode>${invoice.buyerPostCode}</ram:PostcodeCode>
` : "";
	const buyerAddress1 = invoice.buyerAddress1 ? xml`          <ram:LineOne>${invoice.buyerAddress1}</ram:LineOne>
` : "";
	const buyerAddress2 = invoice.buyerAddress2 ? xml`          <ram:LineTwo>${invoice.buyerAddress2}</ram:LineTwo>
` : "";
	const buyerCity = invoice.buyerCity ? xml`          <ram:CityName>${invoice.buyerCity}</ram:CityName>
` : "";
	const buyerCountry = invoice.buyerCountry ? xml`          <ram:CountryID>${invoice.buyerCountry}</ram:CountryID>
` : "";
	const buyerState = invoice.buyerCountrySubdivision ? xml`          <ram:CountrySubDivisionName>${invoice.buyerCountrySubdivision}</ram:CountrySubDivisionName>
` : "";
	const buyerMail = invoice.buyerElectronicAddress ? xml`        <ram:URIUniversalCommunication>
          <ram:URIID schemeID="EM">${invoice.buyerElectronicAddress}</ram:URIID>
        </ram:URIUniversalCommunication>
` : "";

	const buyer = `      <ram:BuyerTradeParty>
        ${buyerId}
        ${buyerName}
        <ram:PostalTradeAddress>
${buyerPostCode}${buyerAddress1}${buyerAddress2}${buyerCity}${buyerCountry}${buyerState}        </ram:PostalTradeAddress>
${buyerMail}      </ram:BuyerTradeParty>
`;

	// --- compile the resulting fragment identifying trading seller and buyer
	return `
    <ram:ApplicableHeaderTradeAgreement>
      ${xml`<ram:BuyerReference>${invoice.buyerReference || "n/a"}</ram:BuyerReference>`}
${seller}${buyer}    </ram:ApplicableHeaderTradeAgreement>
`;
}

/**
 * Renders XML describing payment instructions and providing details on
 * cumulated taxes.
 *
 * @param {object} invoice properties of invoice
 * @param {object[]} items qualified items of invoice
 * @param {object[]} totals cumulated amounts per tax category
 * @param {object} context custom information provided by user in setup code
 * @returns {string} rendered XML
 */
export function renderPaymentXml( invoice, items, totals, context ) { // eslint-disable-line no-unused-vars
	const currency = xml`<ram:InvoiceCurrencyCode>${invoice.invoiceCurrencyCode}</ram:InvoiceCurrencyCode>`;
	const paymentMeansType = xml`<ram:TypeCode>${invoice.paymentMeansTypeCode}</ram:TypeCode>`;
	const accountId = invoice.paymentAccountIdentifier ? xml`          <ram:IBANID>${invoice.paymentAccountIdentifier}</ram:IBANID>
` : "";
	const accountName = invoice.paymentAccountName ? xml`          <ram:AccountName>${invoice.paymentAccountName}</ram:AccountName>
` : "";
	const payeeParty = accountId || accountName ? `        <ram:PayeePartyCreditorFinancialAccount>
${accountId}${accountName}        </ram:PayeePartyCreditorFinancialAccount>
` : "";
	const pspId = invoice.paymentServiceProviderIdentifier ? xml`        <ram:PayeeSpecifiedCreditorFinancialInstitution>
          <ram:BICID>${invoice.paymentServiceProviderIdentifier}</ram:BICID>
        </ram:PayeeSpecifiedCreditorFinancialInstitution>
` : "";

	const taxXml = [];
	const net = totals.reduce( ( sum, total ) => sum + total.net, 0 );
	const gross = totals.reduce( ( sum, total ) => sum + total.gross, 0 );
	const tax = totals.reduce( ( sum, total ) => sum + total.tax, 0 );

	for ( const total of totals ) {
		if ( !total.taxLevel ) {
			throw new Error( "invoice has items without tax - not yet supported in XRechnung" );
		}

		taxXml.push( `      <ram:ApplicableTradeTax>
        ${monetary( total.tax, "ram:CalculatedAmount" )}
        <ram:TypeCode>VAT</ram:TypeCode>
        ${monetary( total.net, "ram:BasisAmount" )}
        <ram:CategoryCode>S</ram:CategoryCode>
        ${percentage( total.taxLevel, "ram:RateApplicablePercent" )}
      </ram:ApplicableTradeTax>
` );
	}

	const invoicingPeriod = invoice.invoicingPeriodStartDate && invoice.invoicingPeriodEndDate ? xml`      <ram:BillingSpecifiedPeriod>
        <ram:StartDateTime>
          <udt:DateTimeString format="102">${formatDate102( invoice.invoicingPeriodStartDate )}</udt:DateTimeString>
        </ram:StartDateTime>
        <ram:EndDateTime>
          <udt:DateTimeString format="102">${formatDate102( invoice.invoicingPeriodEndDate )}</udt:DateTimeString>
        </ram:EndDateTime>
      </ram:BillingSpecifiedPeriod>
` : "";

	const dueDate = invoice.paymentDueDate ? xml`      <ram:SpecifiedTradePaymentTerms>
        <ram:DueDateDateTime>
          <udt:DateTimeString format="102">${formatDate102( invoice.paymentDueDate )}</udt:DateTimeString>
        </ram:DueDateDateTime>
      </ram:SpecifiedTradePaymentTerms>
` : "";

	return `    <ram:ApplicableHeaderTradeSettlement>
      ${currency}
      <ram:SpecifiedTradeSettlementPaymentMeans>
        ${paymentMeansType}
${payeeParty}${pspId}      </ram:SpecifiedTradeSettlementPaymentMeans>
${taxXml.join( "" )}${invoicingPeriod}${dueDate}      <ram:SpecifiedTradeSettlementHeaderMonetarySummation>
        ${monetary( net, "ram:LineTotalAmount" )}
        ${monetary( 0, "ram:ChargeTotalAmount" )}
        ${monetary( 0, "ram:AllowanceTotalAmount" )}
        ${monetary( net, "ram:TaxBasisTotalAmount" )}
        ${monetary( tax, "ram:TaxTotalAmount", invoice.invoiceCurrencyCode )}
        ${monetary( gross, "ram:GrandTotalAmount" )}
        ${monetary( 0, "ram:TotalPrepaidAmount" )}
        ${monetary( gross, "ram:DuePayableAmount" )}
      </ram:SpecifiedTradeSettlementHeaderMonetarySummation>
    </ram:ApplicableHeaderTradeSettlement>
`;
}

/**
 * Renders single XML element representing a monetary amount.
 *
 * @param {string|number} value amount to represent, gets rounded to two decimal digits
 * @param {string} name qualified name of XML element to render
 * @param {string} currency currency code to use for a monetary amount, omit to render the element w/o the according attribute
 * @returns {string} rendered XML code
 */
function monetary( value, name, currency = "" ) {
	const number = round( value, 2 );
	if ( isNaN( number ) ) {
		throw new Error( "invalid amount value " + value );
	}

	const currencyXml = currency ? xml` currencyID="${currency}"` : "";

	return `<${name}${currencyXml}>${number}</${name}>`;
}

/**
 * Renders single XML element representing a percentage.
 *
 * @param {string|number} value percentage to represent
 * @param {string} name name of XML element to render
 * @returns {string} rendered XML code
 */
function percentage( value, name ) {
	return monetary( value, name );
}

/**
 * Renders single XML element representing a quantity.
 *
 * @param {string|number} value amount to represent, gets rounded to two decimal digits
 * @param {string} name qualified name of XML element to render
 * @returns {string} rendered XML code
 */
function quantity( value, name ) {
	const number = round( value, 4 );
	if ( isNaN( number ) ) {
		throw new Error( "invalid amount value " + value );
	}

	return `<${name}>${number}</${name}>`;
}

/**
 * Lists function characters to escape and the replacement escaping either one.
 */
const xmlCodes = {
	"<": "&lt;",
	">": "&gt;",
	"&": "&amp;",
	'"': "&quot;",
};

/**
 * Matches a function character in XML.
 */
const ptnXmlCode = new RegExp( "[" + Object.keys( xmlCodes ).join( "" ) + "]", "g" );

/**
 * Returns escaping counterpart for provided functional XML character.
 *
 * @param {string} code XML character to replace/escape
 * @returns {string} counterpart escaping provided XML character
 */
const encodeXmlCode = code => xmlCodes[code];

/**
 * Implements tag function for template literals assumed to contain XML
 * character data to be encoded properly.
 *
 * @param {string[]} literals segments of literal strings to be encoded
 * @param {any} values list of expressions found between those segments
 * @returns {string} re-concatenated string of literals and expressions with contained XML codes in either string value escaped
 */
function cdata( literals, ...values ) {
	const result = new Array( literals.length + values.length );
	let write = 0;

	for ( let read = 0; read < literals.length; read++ ) {
		result[write++] = literals[read].replace( ptnXmlCode, encodeXmlCode );

		const value = values[read];

		if ( value != null ) {
			result[write++] = String( value ).replace( ptnXmlCode, encodeXmlCode );
		}
	}

	result.splice( write );

	return result.join( "" );
}

/**
 * Implements tag function for template literals assumed to contain XML
 * code with values to be represented as character data in embedded expressions.
 *
 * @param {string[]} literals segments of literal strings containing XML code not to be escaped
 * @param {any} values list of expressions found between those segments
 * @returns {string} re-concatenated string of literals and expressions with contained XML codes in expressions as string value escaped
 */
function xml( literals, ...values ) {
	const result = new Array( literals.length + values.length );
	let write = 0;

	for ( let read = 0; read < literals.length; read++ ) {
		result[write++] = literals[read];

		const value = values[read];

		if ( value != null ) {
			result[write++] = String( value ).replace( ptnXmlCode, encodeXmlCode );
		}
	}

	result.splice( write );

	return result.join( "" );
}

/**
 * Renders provided date for use in an XML with format YYYYMMDD.
 *
 * @param {Date|number} date formatted date
 * @returns {undefined|null|string} some nullish value as provided, formatted date otherwise
 */
function formatDate102( date ) {
	return date == null ? date : formatDate( "%y%0m%0d", new Date( date ) );
}
