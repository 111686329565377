<template>
	<div class="editor" :class="{
		preview: pdf.preview && !isSmallDevice,
		vertical: vertical || isLeanDevice,
		lean: isLeanDevice,
		small: isSmallDevice,
	}">
		<div v-if="!setupVisible" class="form data">
			<div class="head">
				<div class="head-selector">
					<div
						title="klassische Ansicht, für X-Rechnung nicht ausreichend/geeignet"
						:class="{active: headEditor === 'legacy'}"
						@click="setHeadEditor('legacy')"
					>Einfach</div>
					<div
						title="detaillierte Erfassung der Angaben, für X-Rechnung erforderlich"
						:class="{active: headEditor === 'extended'}"
						@click="setHeadEditor('extended')"
					>Erweitert&nbsp;&nbsp;<fa :icon="['fas', 'file-xml']" /></div>
					<div
						title="Angaben über Sie und die Zahlungsmodalitäten, für X-Rechnung erforderlich"
						:class="{active: headEditor === 'seller'}"
						@click="setHeadEditor('seller')"
					>Grundeinstellungen&nbsp;&nbsp;<fa :icon="['fas', 'file-xml']" /></div>
					<div
						title="blendet den Kopfbereich aus"
						class="minimal"
						:class="{active: headEditor === 'minimized'}"
						@click="setHeadEditor('minimized')"
					><fa :icon="['fas', 'down-left-and-up-right-to-center']"/></div>
				</div>
				<SimpleHeadEditor v-if="headEditor === 'legacy'"></SimpleHeadEditor>
				<ExtendedHeadEditor v-else-if="headEditor === 'extended'"></ExtendedHeadEditor>
				<SellerHeadEditor v-else-if="headEditor === 'seller'"></SellerHeadEditor>
				<div v-else class="empty-head"></div>
			</div>
			<div class="items">
				<ItemsEditor></ItemsEditor>
			</div>
		</div>
		<div v-if="setupVisible" class="form setup">
			<SetupEditor></SetupEditor>
		</div>
		<div class="result" v-if="pdf.preview && !isSmallDevice">
			<PdfPreview/>
		</div>
	</div>
</template>

<script>
import PdfPreview from "../container/PdfPreview";
import ItemsEditor from "../editor/ItemsEditor";
import Renderer from "../../renderer/invoice/index";
import SetupEditor from "../editor/SetupEditor";
import SimpleHeadEditor from "../editor/SimpleHeadEditor.vue";
import ExtendedHeadEditor from "../editor/ExtendedHeadEditor.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import SellerHeadEditor from "../editor/SellerHeadEditor.vue";

export default {
	name: "InvoiceEditorView",
	components: {
		SellerHeadEditor,
		ExtendedHeadEditor,
		SimpleHeadEditor,
		SetupEditor,
		ItemsEditor,
		PdfPreview
	},
	data() {
		return {
			$delay: null,
		};
	},
	computed: {
		...mapState( {
			headEditor: state => state.config.headEditor,
			pdf: state => state.pdf,
			vertical: state => state.vertical,
		} ),
		...mapGetters( {
			isSmallDevice: "device/isSmallDevice",
			isLeanDevice: "device/isLeanDevice",
		} ),
		setupVisible() {
			return this.$store.state.setup.visible;
		},
	},
	methods: {
		...mapActions( {
			setHeadEditor: "config/setHeadEditor",
		} ),
		track( action, value ) {
			this.$store.dispatch( `invoice/${action}`, value );
		},
	},
	async created() {
		if ( !this.$store.getters["invoice/hasStorage"] ) {
			await this.$store.dispatch( "invoice/useStorage", localStorage );
		}

		if ( !this.$store.getters["config/hasStorage"] ) {
			await this.$store.dispatch( "config/useStorage", localStorage );
		}

		await this.$store.dispatch( "renderPdf", { renderer: Renderer } );
	},
};
</script>

<style scoped lang="scss">
.editor {
	margin: 0;
	padding: 0;
	position: relative;
	flex: 1000 1 1px;
	display: flex;
	min-height: 0;

	&:not(.vertical) {
		flex-flow: row nowrap;
	}

	&.vertical {
		flex-flow: column nowrap;

		&.preview .form {
			max-height: 50vh;
		}

		.form {
			overflow: auto;
		}
	}
}

.form {
	flex: 1 1 50%;
	position: relative;
	display: flex;
	flex-flow: column nowrap;
	width: 100%;
	min-height: 0;

	> .head {
		flex: 1 0 1px;
		background: var(--header-background);

		.head-selector {
			display: flex;
			flex-flow: row nowrap;
			align-items: stretch;
			justify-content: stretch;
			background: var(--touch-background-active);
			padding: 0.5rem 0.5rem 0 0.5rem;

			> div {
				flex: 100 1 auto;
				text-align: center;
				line-height: 2em;

				&.minimal {
					flex: 1 0 2em;
				}

				&.active {
					background: var(--header-background);
					border-top: var(--touch-frame-width) solid var(--brand-color);
					box-shadow: 0 0 5px #0004;
				}

				&:not(.active) {
					border-top: var(--touch-frame-width) solid transparent;
					cursor: pointer;
				}

				svg {
					color: var(--brand-color);
				}
			}
		}

		.empty-head {
			background: var(--header-background);
			width: 100%;
			height: 0.5rem;
			position: relative;
			z-index: 2;
		}
	}

	> .items {
		min-width: 0;
		min-height: 0;
		flex: 1000 0 1%;
		position: relative;
		box-sizing: border-box;
	}
}

.result {
	flex: 1 1 50%;
}

@media screen and (min-width: 1024px) {
	.form > .items {
		overflow: hidden;
	}
}

@media screen and (max-width: 1799.99px) {
	.editor:not(.vertical) {
		> .form {
			flex: 60 1 50%;
		}

		> .result {
			flex: 40 1 50%;
		}
	}
}
</style>

<style lang="scss">
.editor.vertical .form .head textarea {
	max-height: 4rem;
}
</style>
