<script>
import OptionsPicker from "../2nd-level/OptionsPicker.vue";

export const units = [
	{ value: "", label: "Info", code: "", singular: "", plural: "" },
	{ value: "-", label: "pauschal", code: "H21", singular: "pauschal", plural: "pauschal" },
	{ value: "pc", label: "Stk.", code: "H87", singular: "1 Stück", plural: "%n Stück" },
	{ value: "e", label: "Einh.", code: "E50", singular: "1 Einheit", plural: "%n Einheiten" },
	{ value: "h", label: "Std.", code: "HUR", singular: "1 Stunde", plural: "%n Stunden" },
	{ value: "d", label: "Tag", code: "DAY", singular: "1 Tag", plural: "%n Tage" },
	{ value: "m", label: "Monat", code: "MON", singular: "1 Monat", plural: "%n Monate" },
	{ value: "y", label: "Jahr", code: "ANN", singular: "1 Jahr", plural: "%n Jahre" },
];

/**
 * Maps units supported by legacy invoice editor into unit of measurement codes
 * as required by XRechnung.
 *
 * @param {string} unit local unit of measurement
 * @returns {string|undefined} unit of measurement code, empty string if invoice
 *          item would not appear as such in an XRechnung, undefined if no code
 *          has been found
 */
export const getOfficialUnitCode = unit => {
	for ( const definition of units ) {
		if ( definition.value === unit ) {
			return definition.code;
		}
	}

	return undefined;
};

export default {
	name: "UnitSelector",
	components: { OptionsPicker },
	data() {
		return {
			units: units.map( unit => ( { ...unit } ) ),
		};
	},
	props: {
		prompt: String,
		value: [ String, Number ],
		size: String,
		disabled: Boolean,
	},
};
</script>

<template>
	<OptionsPicker
		prompt="Einheit"
		:options="units"
		:value="value"
		:size="size"
		:disabled="disabled"
		@input="$emit( 'input', $event )"
	/>
</template>

<style scoped lang="scss">
:deep(select:required:invalid) {
	color: var(--touch-text-active);
}
</style>
