import { taxes } from "../components/form/3rd-level/TaxSelector.vue";
import { interpolate, parseNumber } from "../lib/data";

let nextKey = 1;

const defaults = {
	recipient: `{buyerName}
{buyerAddress1}{buyerAddress2:prefix=\\n}
{buyerPostCode} {buyerCity}
{buyerCountry:country}`,
	preface: `Sehr geehrte Damen und Herren,

wir erlauben uns, wie folgt Rechnung zu stellen:`,
	postface: `Bitte überweisen Sie den genannten Gesamtbetrag innerhalb von {paymentTerm} Tagen auf folgendes Konto:

IBAN: {paymentAccountIdentifier}
BIC: {paymentServiceProviderIdentifier}`,
};

/**
 * Describes empty item of invoice as supported in versions of this tool prior
 * to supporting X-Rechnung.
 */
const EmptyLegacyItem = {
	category: "",
	id: "",
	description: "",
	amount: 0,
	unit: undefined,
	price: 0,
	discount: 0,
	tax: 0,
};

/**
 * Describes empty item of invoice.
 */
const EmptyItem = {
	...EmptyLegacyItem,
	date: undefined,
};

/**
 * Generates dedicated object describing single initially empty item of an
 * invoice.
 *
 * @returns {Object} invoice item with all supported properties initialized
 */
export function createNewItem() {
	return { ...EmptyItem };
}

/**
 * Compares two given invoice IDs.
 *
 * @param {string|number} a first invoice ID to compare
 * @param {string|number} b second invoice ID to compare
 * @returns {number} >0 if a>b, <0 if a<b and ==0 if a==b
 */
export function compareInvoiceIds( a, b ) {
	if ( a ) {
		return b ? String( a ).trim().toLowerCase().localeCompare( b.toLowerCase() ) : 1;
	}

	return b ? -1 : 0;
}

/**
 * Invokes provided callback on every saved invoice.
 *
 * @param {object} storage storage containing all saved invoices
 * @param {function(object):(false|any)} callback callback invoked on every found invoice, stops on first invocation returning false
 * @returns {void}
 */
function forEachInvoice( storage, callback ) {
	if ( storage && typeof callback === "function" ) {
		const keys = Object.keys( storage );
		const keyAt = typeof storage.key === "function" ? i => storage.key( i ) : i => keys[i];
		const length = typeof storage.key === "function" ? storage.length : keys.length;

		for ( let i = 0; i < length; i++ ) {
			const key = keyAt( i );
			const match = /^saved:(.+)$/.exec( key );

			if ( match ) {
				let record;

				try {
					record = JSON.parse( typeof storage.getItem === "function" ? storage.getItem( key ) : storage[key] );
				} catch ( e ) {} // eslint-disable-line no-empty

				if ( record && typeof record === "object" ) {
					if ( callback( record, match[1] ) === false ) {
						break;
					}
				}
			}
		}
	}
}

/**
 * Assigns Date instance to a state property of current invoice and syncs it to
 * the local storage instantly.
 *
 * @param {object} state state of properties
 * @param {string} key name of property to change
 * @param {string|number} value new value of named property
 * @returns {void}
 */
const setDate = ( state, key, value ) => {
	const [ , format ] = Model.find( i => i[0] === key ) || [];
	if ( format !== "date" ) {
		return;
	}

	let storageValue;

	if ( value instanceof Date ) {
		state[key] = new Date( value ); // eslint-disable-line no-param-reassign
		state[key].inputFormat = value.inputFormat; // eslint-disable-line no-param-reassign

		storageValue = value.toISOString(); // eslint-disable-line no-param-reassign
	} else if ( value ) {
		const parsed = new Date( value );

		if ( isNaN( parsed ) ) {
			state[key] = String( value ); // eslint-disable-line no-param-reassign
			storageValue = ""; // eslint-disable-line no-param-reassign
		} else {
			state[key] = parsed; // eslint-disable-line no-param-reassign
			storageValue = parsed.toISOString(); // eslint-disable-line no-param-reassign
		}
	} else {
		storageValue = state[key] = ""; // eslint-disable-line no-param-reassign
	}

	if ( state._storage ) {
		if ( typeof state._storage.setItem === "function" ) {
			state._storage.setItem( key, storageValue );
		} else {
			state._storage[key] = storageValue; // eslint-disable-line no-param-reassign
		}
	}
};

/**
 * Assigns string value to a state property of current invoice and syncs it to
 * the local storage instantly.
 *
 * @param {object} state state of properties
 * @param {string} key name of property to change
 * @param {string|number} value new value of named property
 * @returns {void}
 */
const setString = ( state, key, value ) => {
	const [ , format ] = Model.find( i => i[0] === key ) || [];

	if ( format === "string" || format === "numeric" ) {
		state[key] = value == null ? null : String( value ); // eslint-disable-line no-param-reassign

		if ( state._storage ) {
			if ( typeof state._storage.setItem === "function" ) {
				state._storage.setItem( key, value == null ? "" : String( value ) );
			} else {
				state._storage[key] = value == null ? "" : String( value ); // eslint-disable-line no-param-reassign
			}
		}
	}

};

/**
 * Assigns number to a state property of current invoice and syncs it to the
 * local storage instantly.
 *
 * @param {object} state state of properties
 * @param {string} key name of property to change
 * @param {string|number} value new value of named property
 * @returns {void}
 */
const setNumber = ( state, key, value ) => {
	const [ , format ] = Model.find( i => i[0] === key ) || [];

	if ( format === "number" || format === "numeric" ) {
		state[key] = value == null || value === "" ? null : parseNumber( value ); // eslint-disable-line no-param-reassign

		if ( state._storage ) {
			if ( typeof state._storage.setItem === "function" ) {
				state._storage.setItem( key, value == null ? "" : parseNumber( value ) );
			} else {
				state._storage[key] = value == null ? "" : parseNumber( value ); // eslint-disable-line no-param-reassign
			}
		}
	}
};

// eslint-disable-next-line valid-jsdoc
/**
 * Defines model basics of an invoice.
 *
 * For every top-level property of a model this list defines:
 *  - the property's name
 *  - the format
 *  - the initial value or some function generating it on invocation
 *  - a boolean marking a property as _optional_ (primarily for use in GUI)
 *  - the values business term (BT) index according to XRechnung specs
 *  - a boolean marking a property as "floating default": value in latest invoice is used as initial value in a new invoice
 *
 * @type {Array<[string,("string"|"date"|"numeric"),(any|function():any),boolean]>}
 */
const Model = [
	// --- start of legacy properties (see SimpleHeadEditor component)
	[ "invoiceId", "string", null, false, 1 ],
	[ "customerId", "string", null, false, 46 ],
	[ "billingDate", "date", null, false, 2 ],
	[ "deliveryDate", "date", null, false, 72 ],
	[ "preface", "string", () => defaults.preface ],
	[ "postface", "string", () => defaults.postface, false, 20 ],
	[ "recipient", "string", () => defaults.recipient ],
	// --- end of legacy properties

	// --- start of extended properties collected for XRechnung
	//   --- collected per invoice (see ExtendedHeadEditor component)
	[ "invoiceType", "number", 380, false, 3 ],
	[ "paymentDueDate", "date", null, true, 9 ],
	[ "buyerReference", "string", null, false, 10 ],
	[ "purchaseOrderReference", "string", null, false, 13 ],
	[ "salesOrderReference", "string", null, false, 14 ],

	[ "buyerName", "string", null, false, 44 ],
	[ "buyerAddress1", "string", null, false, 50 ],
	[ "buyerAddress2", "string", null, false, 51 ],
	[ "buyerPostCode", "string", null, false, 53 ],
	[ "buyerCity", "string", null, false, 52 ],
	[ "buyerCountry", "string", "DE", false, 55 ],
	[ "buyerCountrySubdivision", "string", null, true, 54 ],
	[ "buyerVatIdentifier", "string", null, true, 48 ],
	[ "buyerLegalRegistrationIdentifier", "string", null, true, 47 ],
	[ "buyerElectronicAddress", "string", null, false, 49 ],

	[ "invoicingPeriodStartDate", "date", null, false, 73 ],
	[ "invoicingPeriodEndDate", "date", null, false, 74 ],
	[ "precedingInvoiceReference", "string", null, true, 25 ],

	//   --- collected per invoice with global defaults (see SellerHeadEditor component)
	[ "invoiceCurrencyCode", "string", "EUR", false, 5, true ],
	[ "paymentMeansTypeCode", "number", 58, false, 81, true ],
	[ "paymentAccountIdentifier", "string", null, false, 84, true ],
	[ "paymentAccountName", "string", null, true, 85, true ],
	[ "paymentServiceProviderIdentifier", "string", null, true, 86, true ],

	[ "sellerIdentifier", "string", null, true, 29, true ],
	[ "sellerName", "string", null, false, 27, true ],
	[ "sellerAddress1", "string", null, false, 35, true ],
	[ "sellerAddress2", "string", null, false, 36, true ],
	[ "sellerPostCode", "string", null, false, 38, true ],
	[ "sellerCity", "string", null, false, 37, true ],
	[ "sellerCountry", "string", "DE", false, 40, true ],
	[ "sellerVatIdentifier", "string", null, true, 31, true ],
	[ "sellerTaxRegistrationIdentifier", "string", null, true, 32, true ],
	[ "sellerLegalRegistrationIdentifier", "string", null, false, 30, true ],
	[ "sellerElectronicAddress", "string", null, false, 34, true ],
	[ "sellerContactPoint", "string", null, false, 41, true ],
	[ "sellerContactTelephoneNumber", "string", null, false, 42, true ],
	[ "sellerContactEmailAddress", "string", null, false, 43, true ],
	// --- end of extended properties collected for XRechnung

	// --- extended properties used to support
	[ "paymentTerm", "number", 14, false, null, true ],	// used to calculate payment due date relative to invoice issue date
	// --- end of extended properties used to support

	[ "items", "array", () => [] ], 			// lists items of current invoice
];

/**
 * Maps format of a property as defined in model into local function eventually
 * implementing code for updating its value in a provided state.
 *
 * @type {Object<string, function(Object, string, any):void>}
 */
const StateWriters = {
	string: ( state, key, value ) => setString( state, key, value ),
	numeric: ( state, key, value ) => setString( state, key, value ),
	number: ( state, key, value ) => setNumber( state, key, value ),
	date: ( state, key, value ) => setDate( state, key, value ),
};

/**
 * Maps format of a property as defined in model into name of mutation to use
 * for updating its value in state.
 *
 * @type {Object<string,string>}
 */
const MutationPerFormat = {
	string: "setString",
	numeric: "setString",
	number: "setNumber",
	date: "setDate",
};

/**
 * Generates action handler triggering mutation for updating a named property
 * of current invoice in state.
 *
 * @param {string} property name of property to update
 * @param {function():(void|Promise)} customFn optional callback to invoke after mutating the state and before triggering update of view/rendered PDF
 * @returns {(function({commit: *, dispatch: *, state: *}, *): Promise<void>)} action handler taking value to write into named property
 */
const generateUpdater = ( property, customFn = undefined ) => {
	const [ , format ] = Model.find( definition => definition[0] === property );
	const mutation = MutationPerFormat[format];

	if ( mutation ) {
		if ( typeof customFn === "function" ) {
			return async( { commit, dispatch, state }, value ) => {
				commit( mutation, { key: property, value } );

				await customFn( { commit, dispatch, state }, { key: property, value } );

				dispatch( "updated", null, { root: true } );
			};
		}


		return ( { commit, dispatch }, value ) => {
			commit( mutation, { key: property, value } );
			dispatch( "updated", null, { root: true } );
		};
	}

	throw new Error( `request for updater regarding unknown or invalid property ${property}` );
};

/**
 * Creates object describing empty invoice with its initial values.
 *
 * @returns {object} properties of an invoice each with its initial value
 */
const createNewInvoice = () => {
	const record = {};

	for ( const [ name, , initial ] of Model ) {
		record[name] = typeof initial === "function" ? initial() : initial;
	}

	return record;
};

/**
 * Syncs state describing payment due date or term of payment when changing some
 * of the involved properties.
 *
 * @param {function} commit callback invoked for mutating the state
 * @param {object} state current state, provided for read access, only
 * @param {string} key name of property that has been changed
 * @returns {void}
 */
const syncPaymentDueDate = ( { commit, state }, { key } ) => {
	const issued = new Date( state.billingDate );
	const due = new Date( state.paymentDueDate );
	const term = Number( state.paymentTerm );

	switch ( key ) {
		case "billingDate" :
		case "paymentTerm" :
			if ( !isNaN( issued ) && !isNaN( term ) ) {
				commit( "setDate", {
					key: "paymentDueDate",
					value: new Date( issued.getTime() + ( term * 86400000 ) ),
				} );
			}
			break;

		case "paymentDueDate" :
			if ( !isNaN( issued ) && !isNaN( due ) ) {
				commit( "setNumber", {
					key: "paymentTerm",
					value: Math.round( ( due.getTime() - issued.getTime() ) / 86400000 ),
				} );
			}
			break;
	}
};

export default {
	namespaced: true,
	state: {
		// mention all properties here to establish code assist
		invoiceId: null,
		customerId: null,
		billingDate: null,
		deliveryDate: null,
		preface: null,
		postface: null,
		recipient: null,
		invoiceType: null,
		invoiceCurrencyCode: null,
		paymentDueDate: null,
		buyerReference: null,
		purchaseOrderReference: null,
		salesOrderReference: null,
		buyerName: null,
		buyerAddress1: null,
		buyerAddress2: null,
		buyerPostCode: null,
		buyerCity: null,
		buyerCountry: null,
		buyerCountrySubdivision: null,
		buyerVatIdentifier: null,
		buyerLegalRegistrationIdentifier: null,
		buyerElectronicAddress: null,
		invoicingPeriodStartDate: null,
		invoicingPeriodEndDate: null,
		precedingInvoiceReference: null,
		paymentMeansTypeCode: null,
		paymentAccountIdentifier: null,
		paymentAccountName: null,
		paymentServiceProviderIdentifier: null,
		sellerIdentifier: null,
		sellerName: null,
		sellerAddress1: null,
		sellerAddress2: null,
		sellerPostCode: null,
		sellerCity: null,
		sellerCountry: null,
		sellerVatIdentifier: null,
		sellerTaxRegistrationIdentifier: null,
		sellerLegalRegistrationIdentifier: null,
		sellerElectronicAddress: null,
		sellerContactPoint: null,
		sellerContactTelephoneNumber: null,
		sellerContactEmailAddress: null,
		paymentTerm: null,

		// have all properties properly initialized
		...createNewInvoice(),

		// make sure non-invoice properties aren't accidentally replaced
		_storage: null,
		saved: [], // lists all previously saved invoices
	},
	getters: {
		hasStorage: state => state._storage != null,
		hasInvoiceId: state => Boolean( state.invoiceId ),
		latestSavedInvoice: state => {
			let latest;

			if ( Array.isArray( state.saved ) ) {
				const length = state.saved.length;

				for ( let i = 0; i < length; i++ ) {
					const invoice = state.saved[i];

					if ( latest ) {
						if ( compareInvoiceIds( invoice?.invoiceId, latest.invoiceId ) > 0 ) {
							latest = invoice;
						}
					} else {
						latest = invoice;
					}
				}
			}

			return latest;
		},
		latestSavedInvoiceId: ( _, getters ) => getters.latestSavedInvoice?.invoiceId ?? "",
		latestInvoiceId: ( state, getters ) => {
			const current = String( state.invoiceId || "" ).trim();
			const latestSaved = getters.latestSavedInvoiceId;

			return compareInvoiceIds( current, latestSaved ) > 0 ? current : latestSaved;
		},
		latestPerCustomer: state => {
			const perCustomer = {};

			for ( const invoice of state.saved ) {
				const { customerId, invoiceId } = invoice || {};
				const trimmed = String( customerId || "" ).trim();

				if ( trimmed ) {
					const current = String( invoiceId || "" ).trim();

					if ( current ) {
						const previous = perCustomer[trimmed];

						if ( previous ) {
							const previousId = String( previous.invoiceId || "" ).trim();

							if ( current.localeCompare( previousId ) > 0 ) {
								perCustomer[trimmed] = invoice;
							}
						} else {
							perCustomer[trimmed] = invoice;
						}
					}
				}
			}

			const filtered = Object.values( perCustomer );

			filtered.sort( ( l, r ) => String( l.customerId ).localeCompare( String( r.customerId ) ) );

			return filtered;
		},
	},
	mutations: {
		useStorage( state, storage ) {
			state._storage = storage; // eslint-disable-line no-param-reassign

			// sync invoice store from provided storage ...
			// ... by re-assigning every property
			for ( const [ key, format, initial ] of Model ) {
				const writer = StateWriters[format];

				if ( !writer ) {
					continue;
				}

				const stored = typeof storage.getItem === "function" ? storage.getItem( key ) : storage[key];

				if ( stored == null ) {
					const value = typeof initial === "function" ? initial() : initial;

					writer( state, key, value );
				} else {
					writer( state, key, stored );
				}
			}

			// ... by recovering its list of items
			let items;

			try {
				items = JSON.parse( typeof storage.getItem === "function" ? storage.getItem( "items" ) : storage.items );
			} catch ( e ) {} // eslint-disable-line no-empty

			if ( Array.isArray( items ) ) {
				state.items = items; // eslint-disable-line no-param-reassign
			} else {
				state.items = []; // eslint-disable-line no-param-reassign

				if ( typeof storage.setItem === "function" ) {
					storage.setItem( "items", "[]" );
				} else {
					storage.items = "[]"; // eslint-disable-line no-param-reassign
				}
			}

			// ... by re-establishing unique keys per current and future items of invoice
			nextKey = 1;

			for ( let i = 0; i < state.items.length; i++ ) {
				state.items[i].key = nextKey++; // eslint-disable-line no-param-reassign
			}
		},
		syncSaved( state ) {
			const storage = state._storage;

			if ( storage ) {
				state.saved.splice( 0 );

				forEachInvoice( storage, invoice => {
					state.saved.push( invoice );
				} );

				state.saved.sort( ( l, r ) => {
					const lid = String( l?.invoiceId || "" ).trim().toLowerCase();
					const rid = String( r?.invoiceId || "" ).trim().toLowerCase();

					return rid.localeCompare( lid );
				} );
			}
		},
		setNumber( state, { key, value } ) {
			setNumber( state, key, value );
		},
		setString( state, { key, value } ) {
			setString( state, key, value );
		},
		setDate( state, { key, value } ) {
			setDate( state, key, value );
		},
		setItemProperty( state, { index, property, value } ) {
			const item = state.items[index];

			if ( item && EmptyItem.hasOwnProperty( property ) ) {
				if ( item.hasOwnProperty( property ) ) {
					item[property] = value;
				} else {
					state.items.splice( index, 1, { ...item, [property]: value } );
				}

				if ( state._storage ) {
					if ( typeof state._storage.setItem === "function" ) {
						state._storage.setItem( "items", JSON.stringify( state.items ) );
					} else {
						state._storage.items = JSON.stringify( state.items ); // eslint-disable-line no-param-reassign
					}
				}
			}
		},
		addItem( state, { item, index = Infinity } ) {
			if ( typeof item === "object" && item ) {
				const copy = structuredClone( item );

				copy.key = nextKey++; // eslint-disable-line no-param-reassign

				state.items.splice( index, 0, copy );

				if ( state._storage ) {
					if ( typeof state._storage.setItem === "function" ) {
						state._storage.setItem( "items", JSON.stringify( state.items ) );
					} else {
						state._storage.items = JSON.stringify( state.items ); // eslint-disable-line no-param-reassign
					}
				}
			}
		},
		removeItem( state, index = Infinity ) {
			state.items.splice( index, 1 );

			if ( state._storage ) {
				if ( typeof state._storage.setItem === "function" ) {
					state._storage.setItem( "items", JSON.stringify( state.items ) );
				} else {
					state._storage.items = JSON.stringify( state.items ); // eslint-disable-line no-param-reassign
				}
			}
		},
		moveItem( state, { index, delta } ) {
			const numItems = state.items.length;

			if ( index > -1 && index < numItems ) {
				const newIndex = Math.max( 0, Math.min( numItems - 1, Math.round( ( index || 0 ) + ( delta || 0 ) ) ) );
				if ( newIndex !== index ) {
					const item = state.items.splice( index, 1 ).shift();
					if ( item ) {
						state.items.splice( newIndex, 0, item );
					}

					if ( state._storage ) {
						if ( typeof state._storage.setItem === "function" ) {
							state._storage.setItem( "items", JSON.stringify( state.items ) );
						} else {
							state._storage.items = JSON.stringify( state.items ); // eslint-disable-line no-param-reassign
						}
					}
				}
			}
		},
		clearItems( state ) {
			state.items.splice( 0 );

			if ( state._storage ) {
				if ( typeof state._storage.setItem === "function" ) {
					state._storage.setItem( "items", JSON.stringify( state.items ) );
				} else {
					state._storage.items = JSON.stringify( state.items ); // eslint-disable-line no-param-reassign
				}
			}
		},

		reset( state, { withBasics = true } = {} ) {
			for ( const [ property, format, initial, , , isBasic ] of Model ) {
				if ( !isBasic || withBasics || !state[property] ) {
					const value = typeof initial === "function" ? initial() : initial;

					StateWriters[format]?.( state, property, value );
				}
			}
		},

		save( state, invoice ) {
			const { _storage: storage } = state;
			const { invoiceId: id } = invoice || {};

			if ( storage && id ) {
				if ( typeof storage.setItem === "function" ) {
					storage.setItem( `saved:${id}`, JSON.stringify( invoice ) );
				} else {
					storage[`saved:${id}`] = JSON.stringify( invoice );
				}

				const { saved } = state;
				const { length } = saved;

				for ( let i = 0; i < length; i++ ) {
					const record = saved[i];

					if ( record.invoiceId === id ) {
						saved.splice( i, 1, invoice );
						return;
					}
				}

				saved.push( invoice );
			}
		},

		remove( state, id ) {
			const { _storage: storage } = state;

			if ( storage && id ) {
				if ( typeof storage.removeItem === "function" ) {
					storage.removeItem( `saved:${id}` );
				} else {
					storage[`saved:${id}`] = undefined;
				}

				const { saved } = state;
				const { length } = saved;

				for ( let i = 0; i < length; i++ ) {
					const record = saved[i];

					if ( record.invoiceId === id ) {
						saved.splice( i, 1 );
						break;
					}
				}
			}
		},

		restore( state, record ) {
			if ( state._storage && record && typeof record === "object" && record.invoiceId ) {
				if ( typeof state._storage.setItem === "function" ) {
					state._storage.setItem( `saved:${record.invoiceId}`, JSON.stringify( record ) );
				} else {
					state._storage[`saved:${record.invoiceId}`] = JSON.stringify( record ); // eslint-disable-line no-param-reassign
				}
			}
		}
	},
	actions: {
		async useStorage( { commit, dispatch }, storage ) {
			commit( "useStorage", storage );
			commit( "syncSaved" );
			await dispatch( "updated", null, { root: true } );
		},

		reset( { commit, dispatch } ) {
			commit( "reset", { withBasics: true } );
			commit( "clearItems" );

			dispatch( "addItem" );
			dispatch( "updated", null, { root: true } );
		},

		newInvoice( { commit, dispatch } ) {
			commit( "reset", { withBasics: false } );
			commit( "clearItems" );

			dispatch( "addItem" );
			dispatch( "updated", null, { root: true } );
		},

		setInvoiceId: generateUpdater( "invoiceId" ),
		setCustomerId: generateUpdater( "customerId" ),
		setBillingDate: generateUpdater( "billingDate", syncPaymentDueDate ),
		setDeliveryDate: generateUpdater( "deliveryDate" ),
		setPreface: generateUpdater( "preface" ),
		setPostface: generateUpdater( "postface" ),
		setRecipient: generateUpdater( "recipient" ),

		setInvoiceType: generateUpdater( "invoiceType" ),
		setInvoiceCurrencyCode: generateUpdater( "invoiceCurrencyCode" ),
		setPaymentDueDate: generateUpdater( "paymentDueDate", syncPaymentDueDate ),
		setBuyerReference: generateUpdater( "buyerReference" ),
		setPurchaseOrderReference: generateUpdater( "purchaseOrderReference" ),
		setSalesOrderReference: generateUpdater( "salesOrderReference" ),

		setBuyerName: generateUpdater( "buyerName" ),
		setBuyerAddress1: generateUpdater( "buyerAddress1" ),
		setBuyerAddress2: generateUpdater( "buyerAddress2" ),
		setBuyerPostCode: generateUpdater( "buyerPostCode" ),
		setBuyerCity: generateUpdater( "buyerCity" ),
		setBuyerCountry: generateUpdater( "buyerCountry" ),
		setBuyerCountrySubdivision: generateUpdater( "buyerCountrySubdivision" ),
		setBuyerVatIdentifier: generateUpdater( "buyerVatIdentifier" ),
		setBuyerLegalRegistrationIdentifier: generateUpdater( "buyerLegalRegistrationIdentifier" ),
		setBuyerElectronicAddress: generateUpdater( "buyerElectronicAddress" ),

		setInvoicingPeriodStartDate: generateUpdater( "invoicingPeriodStartDate" ),
		setInvoicingPeriodEndDate: generateUpdater( "invoicingPeriodEndDate" ),
		setPrecedingInvoiceReference: generateUpdater( "precedingInvoiceReference" ),

		setPaymentMeansTypeCode: generateUpdater( "paymentMeansTypeCode" ),
		setPaymentAccountIdentifier: generateUpdater( "paymentAccountIdentifier" ),
		setPaymentAccountName: generateUpdater( "paymentAccountName" ),
		setPaymentServiceProviderIdentifier: generateUpdater( "paymentServiceProviderIdentifier" ),

		setSellerIdentifier: generateUpdater( "sellerIdentifier" ),
		setSellerName: generateUpdater( "sellerName" ),
		setSellerAddress1: generateUpdater( "sellerAddress1" ),
		setSellerAddress2: generateUpdater( "sellerAddress2" ),
		setSellerPostCode: generateUpdater( "sellerPostCode" ),
		setSellerCity: generateUpdater( "sellerCity" ),
		setSellerCountry: generateUpdater( "sellerCountry" ),
		setSellerVatIdentifier: generateUpdater( "sellerVatIdentifier" ),
		setSellerTaxRegistrationIdentifier: generateUpdater( "sellerTaxRegistrationIdentifier" ),
		setSellerLegalRegistrationIdentifier: generateUpdater( "sellerLegalRegistrationIdentifier" ),
		setSellerElectronicAddress: generateUpdater( "sellerElectronicAddress" ),
		setSellerContactPoint: generateUpdater( "sellerContactPoint" ),
		setSellerContactTelephoneNumber: generateUpdater( "sellerContactTelephoneNumber" ),
		setSellerContactEmailAddress: generateUpdater( "sellerContactEmailAddress" ),

		setPaymentTerm: generateUpdater( "paymentTerm", syncPaymentDueDate ),

		setItemProperty( { commit, dispatch }, { index, property, value } ) {
			commit( "setItemProperty", { index, property, value } );
			dispatch( "updated", null, { root: true } );
		},
		addItem( { commit, dispatch, rootGetters }, item = undefined ) {
			const copy = item ? {} : createNewItem();

			if ( item ) {
				for ( const [ name, initialValue ] of Object.entries( item ?? EmptyItem ) ) {
					copy[name] = item.hasOwnProperty( name ) ? item[name] : initialValue;
				}
			}

			for ( const [ name, value ] of Object.entries( rootGetters["config/defaults"] ) ) {
				if ( copy[name] == null ) {
					copy[name] = value ?? "";
				}
			}

			copy.amount = copy.amount || 1;
			copy.price = copy.price || 0;
			copy.discount = copy.discount || 0;
			copy.tax = copy.tax || taxes[0].value;
			copy.unit ??= "";

			commit( "addItem", {
				item: copy,
				index: item?.index ?? Infinity,
			} );
			dispatch( "updated", null, { root: true } );
		},
		removeItem( { commit, dispatch }, index ) {
			commit( "removeItem", index );
			dispatch( "updated", null, { root: true } );
		},
		moveItem( { commit, dispatch }, { index, delta } ) {
			commit( "moveItem", { index, delta } );
			dispatch( "updated", null, { root: true } );
		},
		clearItems( { commit, dispatch } ) {
			commit( "clearItems" );
			dispatch( "updated", null, { root: true } );
		},

		export( { state } ) {
			if ( state._storage ) {
				const dump = {};

				for ( const [ key, format ] of Model ) {
					if ( format === "date" ) {
						dump[key] = state[key]?.getTime?.() || null;
					} else {
						dump[key] = state[key] ?? null;
					}
				}

				return dump;
			}

			return null;
		},

		import( { commit, dispatch, state }, { invoice, ignoreUnset = false } = {} ) {
			const _invoice = invoice || {};

			for ( const [ key, format ] of Model ) {
				const mutation = MutationPerFormat[format];
				const value = _invoice[key] ?? null;

				if ( mutation && ( ( value != null && value !== "" ) || !ignoreUnset ) ) {
					commit( mutation, { key, value } );
				}
			}

			commit( "clearItems" );

			for ( const item of _invoice.items || [] ) {
				if ( item && typeof item === "object" ) {
					commit( "addItem", { item, index: Infinity, } );
				}
			}

			if ( state.items.length < 1 ) {
				commit( "addItem", { item: {}, index: Infinity } );
			}

			if ( !isNaN( state.paymentTerm ) ) {
				dispatch( "setPaymentTerm", state.paymentTerm );
			}

			dispatch( "updated", null, { root: true } );
		},

		async save( { commit, dispatch, state } ) {
			if ( state._storage ) {
				commit( "save", await dispatch( "export" ) );
			}
		},

		load( { dispatch, commit, state }, invoiceId ) {
			if ( invoiceId && state._storage ) {
				let invoice;

				if ( typeof state._storage.getItem === "function" ) {
					invoice = state._storage.getItem( `saved:${invoiceId}` );
				} else {
					invoice = state._storage[`saved:${invoiceId}`];
				}

				if ( invoice ) {
					let parsed;

					try {
						parsed = JSON.parse( invoice );
					} catch ( e ) {} // eslint-disable-line no-empty

					if ( parsed ) {
						commit( "reset", { withBasics: false } );
						return dispatch( "import", {
							invoice: parsed,
							ignoreUnset: true,
						} );
					}
				}
			}

			return Promise.resolve();
		},

		remove( { commit, state }, invoiceId ) {
			if ( invoiceId && state._storage ) {
				commit( "remove", invoiceId );
			}
		},

		backup( { state } ) {
			if ( state._storage ) {
				const dump = {};

				forEachInvoice( state._storage, ( invoice, invoiceKey ) => {
					dump[invoiceKey] = invoice;
				} );

				return dump;
			}

			return null;
		},

		restore( { commit, state }, dump ) {
			if ( state._storage && dump && typeof dump === "object" ) {
				const keys = typeof state._storage.key === "function" ? state._storage : Object.keys( state._storage );
				const toRemove = [];

				for ( let index = 0; index < keys.length; index++ ) {
					const key = typeof state._storage.key === "function" ? state._storage.key( index ) : keys[index];

					if ( key.startsWith( "saved:" ) ) {
						toRemove.push( key );
					}
				}

				for ( const key of toRemove ) {
					state._storage.removeItem( key );
				}

				for ( const key of Object.keys( dump ) ) {
					const record = dump[key];

					if ( record && typeof record === "object" && record.invoiceId && record.invoiceId === key ) {
						commit( "restore", record );
					}
				}

				commit( "syncSaved" );
			}
		},

		interpolate( { state }, text ) {
			return interpolate( text, ( key, _, optionsApplicator ) => {
				const lowered = key.toLowerCase();

				for ( let k = 0; k < Model.length; k++ ) {
					const [ property, , , , btIndex ] = Model[k];

					if ( lowered === property.toLowerCase() ||
						lowered === `bt-${btIndex}` ||
						lowered === `bt${btIndex}` ) {
						return optionsApplicator( state[property] ?? "" );
					}
				}

				return undefined;
			} );
		}
	},
	modules: {
	},
};
