<template>
	<div class="setup">
		<div class="tabs">
			<FormButton
				flat
				:selected="panel === 'context'"
				@click="show('context')"
			>Kontext</FormButton>
			<span><fa :icon="['fas', 'arrow-alt-right']"></fa></span>
			<FormButton
				flat
				:selected="panel === 'table'"
				@click="show('table')"
			>Postenliste</FormButton>
			<span><fa :icon="['fas', 'arrow-alt-right']"></fa></span>
			<FormButton
				flat
				:selected="panel === 'summary'"
				@click="show('summary')"
			>Zusammenfassung</FormButton>
			<span><fa :icon="['fas', 'arrow-alt-right']"></fa></span>
			<FormButton
				flat
				:selected="panel === 'document'"
				@click="show('document')"
			>Dokument</FormButton>
		</div>
		<div class="panels">
			<CodeEditor
				ref="context"
				v-show="panel === 'context'"
				v-model="contextModel"
			/>
			<CodeEditor
				ref="table"
				v-show="panel === 'table'"
				v-model="tableModel"
			/>
			<CodeEditor
				ref="summary"
				v-show="panel === 'summary'"
				v-model="summaryModel"
			/>
			<CodeEditor
				ref="document"
				v-show="panel === 'document'"
				v-model="documentModel"
			/>
		</div>
		<div class="control">
			<FormButton
				flat
				:selected="true"
				@click="help = 'security'"
			>Sicherheitshinweise</FormButton>
			<FormButton
				flat
				:selected="help === 'reference'"
				@click="help = 'reference'"
			>Hilfe</FormButton>
			<FormButton
				flat
				@click="resetSetup"
			>Alle zurücksetzen</FormButton>
		</div>
		<div class="help" v-if="help">
			<div
				class="content"
				v-html="helpContent"
			></div>
			<div class="closer">
				<FormButton
					flat
					@click="help = null"
				><fa :icon="['fas', 'times']"></fa></FormButton>
			</div>
		</div>
	</div>
</template>

<script>
import FormButton from "../form/1st-level/FormButton";
import Security from "../../assets/help/security.shtml";
import Reference from "../../assets/help/reference.shtml";
import { mapActions, mapState } from "vuex";

export default {
	name: "SetupEditor",
	components: {
		CodeEditor: () => import( /* webpackChunkName: "setup" */ "../form/1st-level/CodeEditor" ),
		FormButton,
	},
	data() {
		return {
			panel: "context",
			help: null,
		};
	},
	computed: {
		...mapState( {
			setup: "setup",
		} ),
		contextModel: {
			get() { return this.setup.context; },
			set( value ) { return this.setContextCode( value ); },
		},
		tableModel: {
			get() { return this.setup.table; },
			set( value ) { return this.setTableCode( value ); },
		},
		summaryModel: {
			get() { return this.setup.summary; },
			set( value ) { return this.setSummaryCode( value ); },
		},
		documentModel: {
			get() { return this.setup.document; },
			set( value ) { return this.setDocumentCode( value ); },
		},
		helpContent() {
			const trimmer = /^[\s\S]*?<body[^>]*>|<\/body\b[\s\S]+$|<(?:script|link|object|iframe)\b[^>]*>/gi;

			switch ( this.help ) {
				case "security" :
					return Security.replace( trimmer, "" );
				case "reference" :
					return Reference.replace( trimmer, "" );

				default : return "";
			}
		},
	},
	methods: {
		...mapActions( {
			setContextCode: "setup/setContext",
			setTableCode: "setup/setTable",
			setSummaryCode: "setup/setSummary",
			setDocumentCode: "setup/setDocument",
		} ),
		resetSetup() {
			if ( confirm( "Sollen wirklich alle Ihre Änderungen verworfen und die Werkseinstellungen wiederhergestellt werden?" ) ) {
				this.$store.commit( "setup/reset" );
			}
		},
		show( name ) {
			if ( this.$refs[name] ) {
				this.panel = name;
				setTimeout( () => {
					this.$refs[name].focus();
				}, 50 );
			}
		},
	},
};
</script>

<style scoped lang="scss">
.setup {
	display: flex;
	height: 100%;
	flex-flow: column nowrap;
	justify-content: stretch;
	align-items: stretch;

	> .tabs {
		flex: 0 0 var(--form-line-height);
		display: flex;
		flex-flow: row;
		justify-content: flex-start;
		align-items: stretch;
		padding-top: 0.5rem;
		background: var(--header-background);
		padding-left: 1rem;

		> button {
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
			padding-left: 0.5em;
			padding-right: 0.5em;
		}

		> span {
			padding: 0 0.5rem;
			opacity: 0.4;
			line-height: var(--form-line-height);
			position: relative;
			top: 0.2rem;
			color: var(--dark-text-color);
		}
	}

	> .panels {
		flex: 1000 1 auto;
		position: relative;

		> * {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}

	> .control {
		flex: 0 0 var(--form-line-height);
		background: var(--header-background);
		display: flex;
		flex-flow: row;
		justify-content: flex-end;
		align-items: stretch;
		padding: 0.5rem 0;

		> * {
			margin-right: 1rem;
		}

		button {
			padding-left: 1em;
			padding-right: 1em;
		}
	}

	> .help {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 50%;
		background: white;
		z-index: 100000;

		> .closer {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 100000;

			> button {
				min-width: 3rem;
			}
		}

		> .content {
			padding: 1rem;
			overflow: auto;
			background: var(--touch-background-inactive);
			color: var(--bright-text-color);
			border-left: 1px solid var(--touch-background-focused);
			line-height: 1.681;
			height: 100%;
			box-sizing: border-box;
		}
	}
}
</style>

<style lang="scss">
.setup > .tabs > button {
	opacity: 0.8;

	&:not(:disabled) {
		&.selected, &.selected:hover {
			background: white;
			color: var(--brand-color);
			opacity: 1;
		}
	}
}

.setup > .help > .content {
	hyphens: auto;

	blockquote.danger {
		margin: 1em 0;
		padding: 1em;
		background: #dd0000;
		color: white;
	}

	li {
		margin-top: 0.5em;
	}

	a {
		color: var(--brand-color);
	}

	dt {
		font-weight: bold;
	}
}
</style>
