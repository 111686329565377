<template>
	<div
		class="field"
		:class="{disabled, labelled: label, optional}"
		@keydown.exact.prevent.up="adjust(0, 0, 1)"
		@keydown.exact.prevent.up.shift="adjust(0, 1, 0)"
		@keydown.exact.prevent.up.shift.ctrl="adjust(1, 0, 0)"
		@keydown.exact.prevent.down="adjust(0, 0, -1)"
		@keydown.exact.prevent.down.shift="adjust(0, -1, 0)"
		@keydown.exact.prevent.down.shift.ctrl="adjust(-1, 0, 0)"
		@keydown.exact.prevent.delete.alt="drop"
		@keydown.exact.prevent.t.alt="pickToday"
		@keydown.exact.prevent.h.alt="pickToday"
	>
		<label v-if="label && name" :for="name">{{ label }}:</label>
		<label v-else-if="label">{{ label }}:</label>
		<label v-if="pre" class="pre">{{ pre }}</label>
		<TextInput
			v-if="name"
			type="date"
			ref="input"
			:class="['align-'+align]"
			:disabled="disabled"
			:id="name"
			:placeholder="prompt"
			:style="style"
			:value="formatted"
			@input="onchange"
		/>
		<TextInput
			v-if="!name"
			type="date"
			ref="input"
			:class="['align-'+align]"
			:disabled="disabled"
			:placeholder="prompt"
			:style="style"
			:value="formatted"
			@input="onchange"
		/>
		<FormButton
			flat
			title="heute (Alt+H)"
			@click="pickToday"
		><fa :icon="['fas', 'crosshairs']" /></FormButton>
		<FormButton
			flat
			title="löschen (Alt+Entf)"
			@click="drop"
		><fa :icon="['fas', 'x']" /></FormButton>
		<label v-if="post" class="post">{{ post }}</label>
	</div>
</template>

<script>
import { formatDate } from "../../../lib/data";
import TextInput from "../1st-level/TextInput";
import FormButton from "../1st-level/FormButton";

export default {
	name: "TextDate",
	components: { FormButton, TextInput },
	props: {
		align: {
			type: String,
			default: "left",
		},
		disabled: Boolean,
		label: String,
		name: String,
		optional: Boolean,
		post: String,
		pre: String,
		prompt: String,
		size: String,
		value: [ String, Number, Date ],
	},
	data() {
		return {
			current: formatDate( "%Y-%m-%d", this.value ),
		};
	},
	computed: {
		style() {
			return this.size ? { width: this.size } : null;
		},
		validated() {
			return this.validate( this.value );
		},
		formatted() {
			const validated = this.validated;

			return validated instanceof Date ? formatDate( "%Y-%0m-%0d", validated ) : validated;
		},
	},
	methods: {
		validate( value ) {
			if ( value instanceof Date ) {
				return value;
			}

			const filtered = value == null ? "" : String( value ).trim();

			if ( filtered === "" ) {
				return "";
			}

			for ( const [ pattern, format, display ] of [
				[ /^(\d{1,2})\.(\d{1,2})\.(\d{4})(\s|T|$)/, "3-02-01", "%d.%m.%Y" ],
				[ /^(\d{4})-(\d{1,2})-(\d{1,2})(\s|T|$)/, "1-02-03", "%Y-%0m-%d" ],
				[ /^(\d{1,2})\/(\d{1,2})\/(\d{4})(\s|T|$)/, "3-02-01", "%0d/%0m/%Y" ],
			] ) {
				const match = pattern.exec( value );
				if ( match ) {
					const _date = new Date( format.replace( /(0?)([1-3])/g, ( _, pad, index ) => {
						return pad ? String( "0" + match[parseInt( index )] ).slice( -2 ) : match[parseInt( index )];
					} ) );

					if ( !isNaN( _date ) ) {
						_date.inputFormat = display;
						return _date;
					}
				}
			}

			if ( /[^\d./-]/.test( filtered ) ) {
				const date = new Date( filtered );
				if ( !isNaN( date ) ) {
					return date;
				}
			}

			return filtered;
		},
		onchange( value ) {
			const parsed = this.validate( value );

			if ( parsed === "" || parsed instanceof Date ) {
				this.$emit( "input", parsed );
			}
		},
		pickToday() {
			this.$emit( "input", new Date() );
			this.$refs.input.focus();
		},
		drop() {
			this.$emit( "input", "" );
			this.$refs.input.focus();
		},
		adjust( year, month, day ) {
			const date = this.validated;

			if ( date === "" ) {
				this.$emit( "input", new Date() );
			} else if ( date instanceof Date ) {
				if ( year ) {
					date.setFullYear( date.getFullYear() + year );
				}

				if ( month ) {
					date.setMonth( date.getMonth() + month );
				}

				if ( day ) {
					date.setDate( date.getDate() + day );
				}

				this.$emit( "input", new Date( date ) );
			}
		},
	},
};
</script>

<style scoped lang="scss">
.field {
	position: relative;
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
	justify-content: stretch;

	&.disabled {
		visibility: hidden;
	}

	&.labelled {
		margin-top: 0.7em;

		> label {
			position: absolute;
			bottom: 100%;
			left: 0;
			font-size: 0.7em;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 100%;
		}
	}

	&.optional input, &.optional button:not(:hover) {
		background: var(--touch-background-active-optional);
	}
}

input {
	flex: 100 1 auto;

	&.align-right {
		text-align: right;
	}
}

.pre {
	flex: 1 1 auto;
	padding: 0.2rem 0.3rem 0.2rem 0;
	text-align: right;
}

.post {
	flex: 1 1 auto;
	padding: 0.2rem 0 0.2rem 0.3rem;
}
</style>
