import defaults from "!!raw-loader!../renderer/invoice/defaults"; // eslint-disable-line import/no-unresolved
import { comments } from "@/renderer/invoice/comments";


/**
 * Extracts code of a default function.
 *
 * @param {string} name name of default function to process
 * @returns {string} code of selected function's body
 */
function extractDefault( name ) {
	const start = `@@defaults:${name}:start`;
	const end = `@@defaults:${name}:end`;

	const startPos = defaults.indexOf( start );
	const endPos = defaults.indexOf( end );

	if ( startPos > -1 && endPos > startPos ) {
		let code = defaults
			.substring( startPos + start.length, endPos )
			.replace( /^.*\n|.*$/g, "" );

		const lines = code.split( /\r?\n/ );
		const indent = String( lines[0] || "" ).replace( /\S.*$/, "" );

		if ( lines.every( line => line.trim() === "" || line.startsWith( indent ) ) ) {
			code = lines.map( line => line.substr( indent.length ) ).join( "\n" );
		}

		return comments.common.replace( /%%name%%/gi, name ).replace( /^\s*/, "" ) +
		       ( comments[name] || "" ).replace( /^\s*/, "" ) +
		       code;
	}

	throw new Error( `unknown setup section "${name}"` );
}

export default {
	namespaced: true,
	state: {
		visible: false,
		context: localStorage.setupContext || extractDefault( "context" ),
		table: localStorage.setupTable || extractDefault( "table" ),
		summary: localStorage.setupSummary || extractDefault( "summary" ),
		document: localStorage.setupDocument || extractDefault( "document" ),
	},
	mutations: {
		show( state ) {
			// eslint-disable-next-line no-param-reassign
			state.visible = true;
		},
		hide( state ) {
			// eslint-disable-next-line no-param-reassign
			state.visible = false;
		},
		setContext( state, code ) {
			// eslint-disable-next-line no-param-reassign
			localStorage.setupContext = state.context = String( code || "" );
		},
		setTable( state, code ) {
			// eslint-disable-next-line no-param-reassign
			localStorage.setupTable = state.table = String( code || "" );
		},
		setSummary( state, code ) {
			// eslint-disable-next-line no-param-reassign
			localStorage.setupSummary = state.summary = String( code || "" );
		},
		setDocument( state, code ) {
			// eslint-disable-next-line no-param-reassign
			localStorage.setupDocument = state.document = String( code || "" );
		},
		reset( state ) {
			localStorage.setupContext = state.context = extractDefault( "context" ); // eslint-disable-line no-param-reassign
			localStorage.setupTable = state.table = extractDefault( "table" ); // eslint-disable-line no-param-reassign
			localStorage.setupSummary = state.summary = extractDefault( "summary" ); // eslint-disable-line no-param-reassign
			localStorage.setupDocument = state.document = extractDefault( "document" ); // eslint-disable-line no-param-reassign
		},
		restore( state, dump ) {
			if ( !dump ) {
				throw new TypeError( "missing dump of setup" );
			}

			[ "context", "table", "summary", "document" ]
				.forEach( name => {
					const value = dump[name];
					if ( value && typeof value !== "string" ) {
						throw new TypeError( `invalid ${name} in setup to restore` );
					}
				} );

			[ "context", "table", "summary", "document" ]
				.forEach( name => {
					// eslint-disable-next-line no-param-reassign
					state[name] = dump[name];
				} );
		},
	},
	actions: {
		show( { commit } ) {
			commit( "show" );
		},
		hide( { commit } ) {
			commit( "hide" );
		},
		backup( { state } ) {
			return Promise.resolve( {
				context: state.context,
				table: state.table,
				summary: state.summary,
				document: state.document,
			} );
		},
		restore( { commit }, dump ) {
			return Promise.resolve()
				.then( () => {
					commit( "restore", dump );
				} );
		},
		setContext( { commit, dispatch }, code ) {
			commit( "setContext", code );
			dispatch( "updated", undefined, { root: true } );
		},
		setTable( { commit, dispatch }, code ) {
			commit( "setTable", code );
			dispatch( "updated", undefined, { root: true } );
		},
		setSummary( { commit, dispatch }, code ) {
			commit( "setSummary", code );
			dispatch( "updated", undefined, { root: true } );
		},
		setDocument( { commit, dispatch }, code ) {
			commit( "setDocument", code );
			dispatch( "updated", undefined, { root: true } );
		},
	},
};
