import { units as Units } from "../components/form/3rd-level/UnitSelector.vue";
import countries from "../assets/lists/iso-3166-1-country-codes.json";

/**
 * This file's exports are exposed as "utils" to code a user can adjust to
 * compile the PDF.
 */

export const taxLabels = {
	19: "zzgl. 19% MwSt.",
	16: "zzgl. 16% MwSt.",
	7: "zzgl. 7% MwSt.",
	5: "zzgl. 5% MwSt.",
	0: "ohne USt.",
	"-5": "inkl. 5% MwSt.",
	"-7": "inkl. 7% MwSt.",
	"-16": "inkl. 16% MwSt.",
	"-19": "inkl. 19% MwSt.",
};

/**
 * Formats provided instance of Date as string according to provided format.
 *
 * @param {string} format format descriptor
 * @param {Date} date instance of Date to be formatted
 * @returns {string} formatted date
 */
export function formatDate( format, date = new Date() ) {
	if ( !( date instanceof Date ) ) {
		return "";
	}

	if ( isNaN( date ) ) {
		return "INVALID!";
	}

	return format.replace( /%(0)?([a-z])/ig, ( _, padding, marker ) => {
		switch ( marker.toLowerCase() ) {
			case "d" : return padding ? String( "0" + date.getDate() ).slice( -2 ) : date.getDate();
			case "m" : return padding ? String( "0" + ( date.getMonth() + 1 ) ).slice( -2 ) : date.getMonth() + 1;
			case "y" : return date.getFullYear();
			case "h" : return padding ? String( "0" + date.getHours() ).slice( -2 ) : date.getHours();
			case "i" : return padding ? String( "0" + date.getMinutes() ).slice( -2 ) : date.getMinutes();
			case "s" : return padding ? String( "0" + date.getSeconds() ).slice( -2 ) : date.getSeconds();
			default : return _;
		}
	} );
}

/**
 * Formats provided value as a rendered price tag.
 *
 * @param {number} value price to be rendered
 * @param {string} currency currency of provided price
 * @param {string} locale code of locale to use
 * @returns {string} formatted price with currency
 */
export function formatPrice( value, currency = "EUR", locale = "de" ) {
	return new Intl.NumberFormat( locale, { currency, style: "currency" } ).format( parseNumber( value ) );
}

/**
 * Renders provided numeric value.
 *
 * @param {number} value value to be rendered
 * @param {string} locale code of locale to use
 * @returns {string} formatted number
 */
export function formatNumber( value, locale = "de" ) {
	return new Intl.NumberFormat( locale ).format( parseNumber( value ) );
}

/**
 * Parses numeric value of provided data considering different number format per
 * current or selected locale(s).
 *
 * @param {*} raw any kind of data
 * @param {string} locale locale to consider when parsing for a particular locale's format
 * @returns {number} numeric value of provided data
 */
export function parseNumber( raw, locale = undefined ) {
	if ( typeof raw === "number" || raw == null ) {
		return raw;
	}

	if ( !isNaN( Number( raw ) ) ) {
		return Number( raw );
	}

	const decimal = new Intl.NumberFormat( locale ).format( 1.5 ).replace( /\d/g, "" );
	const [ integer, fraction, more ] = String( raw ).split( decimal );

	if ( more != null ) {
		return NaN;
	}

	if ( fraction != null && !/^\d+$/.test( fraction ) ) {
		return NaN;
	}

	const thousands = new Intl.NumberFormat().format( 1000 ).replace( /\d/g, "" );
	const blocks = thousands ? integer.split( thousands ) : [integer];

	if ( blocks[0].startsWith( "-" ) || blocks[0].startsWith( "+" ) ) {
		blocks[0] = blocks[0].substring( 1 );
	}

	for ( let i = 0; i < blocks.length; i++ ) {
		const block = blocks[i];

		if ( !/^\d+$/.test( block ) ) {
			return NaN;
		}

		if ( block.length === 3 ) {
			continue;
		}

		if ( block.length > 0 && block.length < 3 && i === 0 ) {
			continue;
		}

		if ( block.length > 3 && i === 0 && blocks.length === 1 ) {
			continue;
		}

		return NaN;
	}

	return parseFloat( raw.replace( thousands, "" ).replace( decimal, "." ) );
}

/**
 * Delivers boolean value assumed to be represented by provided raw value.
 *
 * @param {any} raw raw value to parse for boolean value
 * @returns {undefined|boolean} represented boolean value, undefined if no boolean value has been found
 */
export function parseBoolean( raw ) {
	if ( raw != null ) {
		switch ( typeof raw ) {
			case "boolean" :
				return raw;

			case "number" :
				return raw !== 0;

			case "string" :
				if ( /^(?:y(?:es)?|true|on|set|hi(?:gh)?|[1-9]\d*)$/i.test( raw ) ) {
					return true;
				}

				if ( /^(?:no?|false|off|unset|low?|0|)\s*$/i.test( raw ) ) {
					return false;
				}
		}
	}

	return undefined;
}

/**
 * Adjusts provided number by given amount.
 *
 * On providing a number or a string containing a parseable number, the result
 * is that number adjusted by given amount. Parsing the number supports formats
 * specific to current locale.
 *
 * On providing a string containing several sequences of digits, the result is
 * that string with one of the sequence incremented or decremented based on
 * provided index.
 *
 * - The absolute value of the index is used to determine which sequence of
 *   digits to adjust:
 *      1 is selected last sequence in string
 *      2 is selecting second-to-last sequence in string
 *      ...
 * - The sign of the index controls whether the selected sequence of digits
 *   parsed as integer number is increased (>0) or decreased (<0) by 1.
 *
 * @param {any} value some value which may be a number or a string containing something number-like
 * @param {number} amount amount of adjusting a regular number provided as such or as string
 * @param {number} index controls what sequence of numbers in a string to adjust and whether to increase or decrease it by 1
 * @returns {number|string} adjusted value
 */
export function adjustNumeric( value, amount, index ) {
	if ( typeof value === "number" ) {
		return value + amount;
	}

	const number = parseNumber( value );

	if ( isNaN( number ) ) {
		const parts = String( value ?? "" ).split( /(\D+)/ );
		const dir = index < 0 ? -1 : 1;
		let level = Math.abs( index );

		for ( let i = parts.length - 1; i >= 0; i-- ) {
			if ( !/^\d+$/.test( parts[i] ) ) {
				continue;
			}

			if ( --level > 0 ) {
				continue;
			}

			const part = parseInt( parts[i] );

			if ( dir < 0 && part < 1 ) {
				parts[i] = "".padStart( parts[i].length, "9" );
				level++;
			} else if ( dir > 0 && part === Math.pow( 10, parts[i].length ) - 1 ) {
				parts[i] = "".padStart( parts[i].length, "0" );
				level++;
			} else {
				parts[i] = String( part + dir ).padStart( parts[i].length, "0" );
				break;
			}
		}

		return parts.join( "" );
	}

	return number + amount;
}

export const borderTL = { border: [ true, true, false, false ] };
export const borderT = { border: [ false, true, false, false ] };
export const borderTR = { border: [ false, true, true, false ] };
export const borderR = { border: [ false, false, true, false ] };
export const borderBR = { border: [ false, false, true, true ] };
export const borderB = { border: [ false, false, false, true ] };
export const borderBL = { border: [ true, false, false, true ] };
export const borderL = { border: [ true, false, false, false ] };
export const borderN = { border: [ false, false, false, false ] };
export const borderA = { border: [ true, true, true, true ] };
export const borderTBR = { border: [ false, true, true, true ] };
export const borderTBL = { border: [ true, true, false, true ] };
export const borderTB = { border: [ false, true, false, true ] };

export const units = {};
for ( const unit of Units ) {
	units[unit.value] = [ unit.singular, unit.plural ];
}

/**
 * Generates random token.
 *
 * @param {int} size number of characters of resulting token
 * @returns {string} random token
 */
export function randomToken( size ) {
	const dictionary = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-_.:#*+;,=()%&/<>!?";
	const token = new Uint8Array( size );
	window.crypto.getRandomValues( token );

	return [].map.call( token, octet => dictionary.charAt( octet % dictionary.length ) ).join( "" );
}

/**
 * Retrieves SVG code describing single horizontal line.
 *
 * @param {number} length length of resulting line
 * @param {string} color color of line
 * @param {number} width stroke width in point
 * @returns {string} SVG code describing line
 */
export function hLine( length = 100, color = "#000000", width = 0.1 ) {
	return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 1"><line x1="0" y1="0" x2="${length}" y2="0" stroke="${color}" stroke-width="${width}"/></svg>`;
}

/**
 * Interpolates provided text by replacing contained markers with content
 * provided by a processing callback.
 *
 * @param {string} text text assumed to contain markers to replace
 * @param {function(string, Object<string,(true|string)>, function(string):string):string} processor callback invoked with content of a single marker to replace
 * @returns {string} provided text with matching markers replaced
 */
export function interpolate( text, processor ) {
	if ( text == null || typeof processor !== "function" ) {
		return text;
	}

	const parts = String( text ).split( /([{}])/ );

	for ( let i = 0; i < parts.length; i++ ) {
		if ( parts[i] === "{" ) {
			let j, depth = 1;

			for ( j = i + 1; j < parts.length; j++ ) {
				switch ( parts[j] ) {
					case "{" : depth++; break;
					case "}" : depth--; break;
				}

				if ( depth < 1 ) {
					break;
				}
			}

			if ( j > i + 1 && j < parts.length ) {
				const marker = parts.slice( i + 1, j ).join( "" );
				const [ , key, extra = "" ] = /^([^:]*)(?::([\s\S]*))?$/.exec( marker );
				const options = {};

				if ( extra ) {
					const extraParts = extra.split( /([=;])/ );
					let previous = 0, k;

					for ( k = 0; k < extraParts.length; k++ ) {
						switch ( extraParts[k] ) {
							case "=" : {
								const name = extraParts.slice( previous, k ).join( "" ).trim().toLowerCase();
								let l;

								for ( l = k + 1; l < extraParts.length; l++ ) {					// eslint-disable-line max-depth
									if ( extraParts[l] === ";" ) {								// eslint-disable-line max-depth
										if ( extraParts[l - 1].endsWith( "\\" ) ) {				// eslint-disable-line max-depth
											extraParts[l - 1] = extraParts[l - 1].slice( 0, -1 );
										} else {
											options[name] = extraParts.slice( k + 1, l ).join( "" );
											break;
										}
									}
								}

								if ( l === extraParts.length ) {								// eslint-disable-line max-depth
									options[name] = extraParts.slice( k + 1 ).join( "" );
								}

								k = l;
								previous = k + 1;
								break;
							}

							case ";" :
								options[extraParts.slice( previous, k ).join( "" ).trim().toLowerCase()] = true;

								previous = k + 1;
								break;
						}
					}

					if ( previous < extraParts.length && k === extraParts.length ) {
						const name = extraParts.slice( previous, k ).join( "" ).trim().toLowerCase();

						options[name] = true;
					}
				}

				const trimmed = key.trim();

				if ( trimmed === "" ) {
					continue;
				}

				try {
					const value = processor( trimmed, options, replaced => applyOptions( replaced, options ) );

					if ( value != null ) {
						parts.splice( i, j - i + 1, value );
					}
				} catch ( error ) {
					console.error( error );
				}
			}
		}
	}

	return parts.join( "" );
}

/**
 * Applies common options support in interpolating texts to provided one.
 *
 * @note This method isn't parsing for contained markers in provided text but is
 *       meant to be invoked by such a parser with a replacement value to be so
 *       that provided options can be applied to it.
 *
 * @param {string} replacement text originally meant to replace some found marker
 * @param {Object<string,(true|string)>} options options to apply to provided replacement
 * @returns {string} provided replacement string with provided options applied as supported
 */
export function applyOptions( replacement, options ) {
	for ( const key of Object.keys( options ) ) {
		switch ( key ) {
			case "prefix" :
				if ( ( replacement ?? "" ) !== "" ) {
					replacement = String( options[key] ) // eslint-disable-line no-param-reassign
						.replace( /\\n/g, "\n" ) + String( replacement );
				}
				break;

			case "suffix" :
				if ( ( replacement ?? "" ) !== "" ) {
					replacement = String( replacement ) + String( options[key] ) // eslint-disable-line no-param-reassign
						.replace( /\\n/g, "\n" );
				}
				break;

			case "date" :
				if ( ( replacement ?? "" ) !== "" ) {
					const value = options[key];
					const isLocale = value !== true && /^[a-z]{2,3}$/i.test( value.trim() );
					const locale = isLocale ? [value.trim()] : [];

					replacement = new Intl.DateTimeFormat( locale, { // eslint-disable-line no-param-reassign
						dateStyle: isLocale || value === true ? "long" : value
					} ).format( new Date( replacement ) );
				}
				break;

			case "country" :
				for ( const country of countries.daten ) {
					if ( country[0] === replacement ) {
						replacement = country[2]; // eslint-disable-line no-param-reassign
						break;
					}
				}
				break;
		}
	}

	return replacement;
}

/**
 * Converts provided string into sequence of octets representing same string in
 * its UTF-8 encoding.
 *
 * @param {string} string string to convert to UTF-8-encoded octets
 * @returns {Uint8Array} octets representing UTF8-encoded string
 */
export const encode = string => new TextEncoder().encode( string );

/**
 * Converts provided array of octets into string representing same octets in
 * Base64 encoding.
 *
 * @param {Uint8Array|ArrayBuffer} octets array of octets
 * @returns {string} Base64-encoding of provided octets
 */
export const toBase64 = octets => btoa( Array.from( new Uint8Array( octets ), octet => String.fromCodePoint( octet ) ).join( "" ) );

/**
 * Rounds provided numeric value to keep given number of decimal digits at most.
 *
 * @param {string|number} value numeric value to round
 * @param {number} decimals number of decimal digits to keep at most
 * @returns {number} rounded numeric value
 */
export function round( value, decimals = 2 ) {
	const scale = Math.pow( 10, decimals );

	return Math.round( parseNumber( value ) * scale ) / scale;
}
