export const comments = {
	common: `
// This file is describing part of PDF generation. You may adjust it according
// to your needs.
// 
//     SECURITY WARNING!
// 
//     DON'T PASTE CODE FROM UNTRUSTED SOURCES HERE AS IT MIGHT DIMINISH YOUR 
//     BROWSER'S OVERALL SECURITY.
// 
// The code is expected to assign its result to exports.%%name%%, e.g.
// 
//     exports.%%name%% = { ... your data ... };
// 
// It might use these predefined variables:
// 
//  utils       exposing commonly useful methods and predefined values 
//  cm          representing the number of points per cm for defining 
//              extents in cm rather than points
//  invoice     providing basic information on invoice itself
//  items       providing qualified list of invoice items
//  totals      lists total net, tax amount and total gross per tax level
// 
`,
	table: `
// In addition, this particular file may access
// 
//  context     providing data exported from your context definition file
// 
`,
	summary: `
// In addition, this particular file may access
// 
//  context     providing data exported from your context definition file
//  table       providing data exported from your table definition file
// 
`,
	document: `
// In addition, this particular file may access
// 
//  context     providing data exported from your context definition file
//  table       providing data exported from your table definition file
//  summary     providing data exported from your summary definition file
// 
`,
};
