<script>
import { mapState } from "vuex";
import TextDate from "../form/2nd-level/TextDate.vue";
import TextField from "../form/2nd-level/TextField.vue";
import FlexFields from "../container/FlexFields.vue";
import CurrencySelector from "../form/3rd-level/CurrencySelector.vue";
import CountrySelector from "../form/3rd-level/CountrySelector.vue";
import PaymentMeansTypeSelector from "../form/3rd-level/PaymentMeansTypeSelector.vue";

// https://xeinkauf.de/xrechnung/versionen-und-bundles/?hilite=xrechnung&highlight=%22xrechnung%22

export default {
	name: "SellerHeadEditor",
	components: { PaymentMeansTypeSelector, CountrySelector, CurrencySelector, FlexFields, TextField, TextDate },
	computed: {
		...mapState( {
			invoice: state => state.invoice,
		} ),
	},
	methods: {
		update( field, value ) {
			const action = "set" + field[0].toUpperCase() + field.substring( 1 );

			this.$store.dispatch( `invoice/${action}`, value );
		},
	},
};
</script>

<template>
	<FlexFields class="fields">
		<CurrencySelector
			limit-size
			class="w25"
			label="Währung (BT-5)"
			:value="invoice.invoiceCurrencyCode"
			@input="update('invoiceCurrencyCode',$event)"
		></CurrencySelector>
		<TextDate
			class="w25"
			label="Zahlung fällig am (BT-9)"
			:value="invoice.paymentDueDate"
			@input="update('paymentDueDate',$event)"
		></TextDate>
		<TextField
			ref="paymentTerm"
			class="w25"
			label="Zahlungsziel"
			:value="invoice.paymentTerm"
			@input="update('paymentTerm',$event)"
		></TextField>
		<PaymentMeansTypeSelector
			limit-size
			class="w25"
			label="Zahlungsmethode (BT-81)"
			:value="invoice.paymentMeansTypeCode"
			@input="update('paymentMeansTypeCode',$event)"
		></PaymentMeansTypeSelector>
		<TextField
			case="upper"
			class="w25"
			label="Zahlung auf Konto (BT-84)"
			:optional="invoice.paymentMeansTypeCode !== 58 && invoice.paymentMeansTypeCode !== 30"
			:prompt="invoice.paymentMeansTypeCode === 58 ? 'IBAN' : 'Kontonummer'"
			:value="invoice.paymentAccountIdentifier"
			@input="update('paymentAccountIdentifier',$event)"
		></TextField>
		<TextField
			case="upper"
			class="w25"
			label="BIC (BT-86)"
			:optional="invoice.paymentMeansTypeCode !== 30"
			:value="invoice.paymentServiceProviderIdentifier"
			@input="update('paymentServiceProviderIdentifier',$event)"
		></TextField>
		<TextField
			class="w25"
			label="Kontoinhaber (BT-85)"
			optional
			:value="invoice.paymentAccountName"
			@input="update('paymentAccountName',$event)"
		></TextField>
		<h3 class="w100">Verkäufer</h3>
		<TextField
			case="upper"
			class="w25"
			label="Handelsregisternummer (BT-30)"
			optional
			prompt="erforderlich falls vorhanden"
			:value="invoice.sellerLegalRegistrationIdentifier"
			@input="update('sellerLegalRegistrationIdentifier',$event)"
		></TextField>
		<TextField
			case="upper"
			class="w25"
			label="Umsatzsteuer-ID (BT-31)"
			optional
			prompt="erforderlich falls vorhanden"
			:value="invoice.sellerVatIdentifier"
			@input="update('sellerVatIdentifier',$event)"
		></TextField>
		<TextField
			case="upper"
			class="w25"
			label="Steuernummer (BT-32)"
			:value="invoice.sellerTaxRegistrationIdentifier"
			@input="update('sellerTaxRegistrationIdentifier',$event)"
		></TextField>
<!--
		<TextField
			case="upper"
			class="w25"
			label="Verkäufer-ID des Kunden (BT-29)"
			optional
			:value="invoice.sellerIdentifier"
			@input="update('sellerIdentifier',$event)"
		></TextField>
-->
		<TextField
			class="w20"
			label="Name (BT-44)"
			prompt="Name, Firma, ..."
			:value="invoice.sellerName"
			@input="update('sellerName',$event)"
		></TextField>
		<TextField
			class="w40"
			label="Anschrift (BT-50)"
			prompt="Straße und Hausnummer"
			:value="invoice.sellerAddress1"
			@input="update('sellerAddress1',$event)"
		></TextField>
		<TextField
			class="w40"
			label="Anschrift (BT-51)"
			prompt="Ergänzungen"
			:value="invoice.sellerAddress2"
			@input="update('sellerAddress2',$event)"
		></TextField>
		<TextField
			class="w25"
			label="PLZ (BT-53)"
			:value="invoice.sellerPostCode"
			@input="update('sellerPostCode',$event)"
		></TextField>
		<TextField
			class="w25"
			label="Ort (BT-52)"
			:value="invoice.sellerCity"
			@input="update('sellerCity',$event)"
		></TextField>
		<CountrySelector
			limit-size
			class="w25"
			label="Land (BT-55)"
			:value="invoice.sellerCountry"
			@input="update('sellerCountry',$event)"
		></CountrySelector>
		<TextField
			class="w25"
			label="E-Mail (BT-49)"
			prompt="info@..."
			:value="invoice.sellerElectronicAddress"
			@input="update('sellerElectronicAddress',$event)"
		></TextField>
		<TextField
			class="w25"
			label="Name Kontaktperson (BT-41)"
			prompt="bspw. bei Fragen zur Rechnung"
			:value="invoice.sellerContactPoint"
			@input="update('sellerContactPoint',$event)"
		></TextField>
		<TextField
			class="w25"
			label="Telefon Kontaktperson (BT-42)"
			prompt="bspw. bei Fragen zur Rechnung"
			:value="invoice.sellerContactTelephoneNumber"
			@input="update('sellerContactTelephoneNumber',$event)"
		></TextField>
		<TextField
			class="w25"
			label="E-Mail Kontaktperson (BT-43)"
			prompt="bspw. bei Fragen zur Rechnung"
			:value="invoice.sellerContactEmailAddress"
			@input="update('sellerContactEmailAddress',$event)"
		></TextField>
	</FlexFields>
</template>

<style scoped lang="scss">
.fields {
	background: var(--header-background);
	position: relative;
	z-index: 2;
	padding: 1em;

	h3 {
		margin: 0;
	}
}
</style>
