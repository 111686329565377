<template>
	<div id="app" @keydown.ctrl.alt.exact.capture="shortcut" ref="me">
		<div id="panel" @keypress.exact="search">
			<div class="info">
				<span
					title="höchste bisher vergebene Rechnungsnummer in gespeicherten Rechnungen, durch Klicken wird die nächste ID der aktuellen Rechnung zugewiesen"
					class="latest-invoice-id"
					@click="assignNextInvoiceId"
				>{{ latestSavedInvoiceId }}</span>
			</div>
			<div class="storage">
				<div class="group selectors">
					<select ref="invoices" v-model="selectedInvoice"
							@keydown.enter.capture="loadInvoice">
						<option
							value=""
							disabled
							class="prompt"
						>Rechnungen:</option>
						<option
							:value="item.invoiceId"
							v-for="item in invoicesOfCustomer"
							:key="item.invoiceId"
						>{{ item.invoiceId }}</option>
					</select>
					<select ref="customers" v-model="selectedCustomerInvoice">
						<option
							value=""
							disabled
							class="prompt"
						>Kunden:</option>
						<option
							:value="item.invoiceId"
							v-for="item in customers"
							:key="item.invoiceId"
						>
							{{ item.customerId }}
							({{ item.buyerName || item.recipient.replace( /\n[\s\S]+$/, "" ) }})
						</option>
					</select>
				</div>
				<div class="group">
					<ActionButton
						dark
						icon="fad-folder-open"
						:disabled="!hasSelection"
						:title="hasSelection ? 'Rechnung laden …' : 'Wählen Sie zunächst eine Rechnung!'"
						@action="load"
					></ActionButton>
					<ActionButton
						dark
						icon="fad-save"
						:disabled="!hasStorage || !hasInvoiceId"
						:title="hasStorage ? hasInvoiceId ? 'Rechnung lokal im Browser speichern …' : 'Geben Sie eine Rechnungsnummer an!' : 'Es existiert kein lokaler Speicher in diesem Browser!'"
						@action="saveInvoice"
					></ActionButton>
					<ActionButton
						dark
						icon="fad-trash-alt"
						:disabled="!hasSelection"
						:title="hasSelection ? 'Rechnung aus dem lokalen Speicher im Browser entfernen …' : 'Wählen Sie zunächst eine Rechnung!'"
						@action="remove"
					></ActionButton>
					<ActionButton
						dark
						icon="fad-file-alt"
						title="Neue Rechnung erstellen …"
						@action="newInvoice"
					></ActionButton>
					<ActionButton
						dark
						icon="fad-download"
						title="Alle Daten aus dem Browser herunterladen …"
						@action="backup"
					></ActionButton>
					<ActionButton
						dark
						icon="fad-upload"
						title="Alle Daten aus einem hochgeladenen Stand wiederherstellen …"
						@action="restore"
					></ActionButton>

					<div
						class="uploader"
					><input
						type="file"
						accept=".json"
						ref="uploader"
					></div>
				</div>
			</div>
			<div class="tools">
				<ToggleButton
					dark
					icon="fad-drafting-compass"
					:state="setupVisible"
					:title="setupVisible ? 'Ausgabekonfiguration ausblenden …' : 'Ausgabekonfiguration anzeigen…'"
					@toggle="$event ? showSetup() : hideSetup()"
				></ToggleButton>
				<ToggleButton
					dark
					icon="fad-file-pdf"
					:state="pdf.preview"
					:title="pdf.preview ? 'PDF-Vorschau ausblenden …' : 'PDF-Vorschau anzeigen …'"
					:disabled="isSmallDevice"
					@toggle="switchPreview"
				></ToggleButton>
				<ToggleButton
					dark
					icon="fad-border-center-h"
					title="Ausrichtung des Editors umschalten …"
					:state="vertical"
					:disabled="!pdf.preview || isLeanDevice"
					@toggle="switchOrientation"
				></ToggleButton>
				<div
					class="global-error-flag"
					:class="{active: globalError, inactive: !globalError}"
					:title="globalError || 'Zeigt Fehler an, bspw. wenn keine XRechnung erzeugt werden konnte.'"
				><fa
					:class="{'fa-shake': globalError}"
					:icon="['fas', 'triangle-exclamation']"
				/></div>
				<ActionButton
					v-if="!isSmallDevice"
					dark
					icon="fad-sync-alt" title="PDF-Vorschau aktualisieren …"
					:disabled="!pdf.preview"
					@action="refreshPdf"
				></ActionButton>
				<ActionButton
					dark
					icon="fad-external-link"
					title="PDF-Dokument herunterladen …"
					@action="downloadPdf"
				></ActionButton>
				<ActionButton
					dark
					icon="fad-bug"
					title="Supportanfrage oder Fehlermeldung …"
					@action="reportIssue"
				></ActionButton>
			</div>
		</div>
		<router-view ref="content"/>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ToggleButton from "./form/1st-level/ToggleButton";
import ActionButton from "./form/1st-level/ActionButton";
import { formatDate } from "@/lib/data";
import { adjustNumeric } from "../lib/data";

export default {
	data() {
		return {
			selectedInvoice: "",
			selectedCustomerInvoice: "",
			copied: "",
			$copiedTimer: undefined,
		};
	},
	components: { ActionButton, ToggleButton },
	methods: {
		...mapActions( {
			switchOrientation: "switchOrientation",
			switchPreview: "switchPreview",
			showSetup: "setup/show",
			hideSetup: "setup/hide",
			refreshPdf: "rerenderPdf",
			downloadPdf: "downloadPdf",
			newInvoice: "invoice/newInvoice",
			loadInvoice: "invoice/load",
			saveInvoice: "invoice/save",
			removeInvoice: "invoice/remove",
			setInvoiceId: "invoice/setInvoiceId",
		} ),
		reportIssue() {
			const a = document.createElement( "a" );
			a.href = "mailto:bot+cepharum-accounting-150-issue-@git.cepharum.de?subject=Supportanfrage";
			a.click();
		},
		backup() {
			Promise.all( [
				this.$store.dispatch( "invoice/backup" ),
				this.$store.dispatch( "setup/backup" ),
				this.$store.dispatch( "config/backup" ),
			] )
				.then( ( [ invoices, setup, config ] ) => {
					const url = URL.createObjectURL( new Blob(
						[JSON.stringify( { version: 2, invoices, setup, config } )],
						{ type: "text/json;charset=utf-8" }
					) );

					const anchor = document.createElement( "a" );
					anchor.href = url;
					anchor.target = "_blank";
					anchor.download = `invoices-dump-${formatDate( "%y-%0m-%0d %0h-%0i" )}.json`;

					anchor.click();

					URL.revokeObjectURL( url );
				} )
				.catch( cause => {
					console.error( cause );
					alert( "Backup failed." );
				} );
		},
		restore() {
			this.$refs.uploader.onchange = () => {
				const file = this.$refs.uploader.files[0];

				if ( file ) {
					file.text()
						.then( json => { // eslint-disable-line consistent-return
							let data;

							try {
								data = JSON.parse( json );
							} catch ( e ) {} // eslint-disable-line no-empty

							if ( data && typeof data === "object" ) {
								if ( data.invoices && typeof data.invoices === "object" &&
									 data.setup && typeof data.setup === "object" &&
									 data.version > 0 ) {
									return Promise.all( [
										this.$store.dispatch( "invoice/restore", data.invoices ),
										this.$store.dispatch( "setup/restore", data.setup ),
										this.$store.dispatch( "config/restore", data.config ),
									] );
								}

								return this.$store.dispatch( "invoice/restore", data );
							}

							throw new Error( "empty import" );
						} )
						.catch( cause => {
							console.error( cause );
							alert( "Restoring data failed. See JS console for additional information!" );
						} );
				}
			};
			this.$refs.uploader.click();
		},
		async load() {
			if ( this.selectedInvoice ) {
				await this.loadInvoice( this.selectedInvoice );
			} else if ( this.selectedCustomerInvoice ) {
				await this.loadInvoice( this.selectedCustomerInvoice );
			}

			this.$refs.content.$el.querySelector( "input, textarea, select" )?.focus();

			this.selectedInvoice = this.selectedCustomerInvoice = "";
		},
		async remove() {
			const id = this.selectedInvoice;

			if ( id && confirm( `Soll die Rechnung ${id} wirklich gelöscht werden?` ) ) {
				await this.removeInvoice( id );

				this.$refs.content.$el
					.querySelector( "input, textarea, select" )
					?.focus();
			}

			this.selectedInvoice = this.selectedCustomerInvoice = "";
		},
		search( event ) {
			clearTimeout( this._resetTimer );
			this._resetTimer = setTimeout( () => { this._query = ""; }, 2000 );

			const query = this._query = ( ( this._query || "" ) + event.key ).toLowerCase();
			this.selectedInvoice = "";

			let customerCandidate = "";
			const pool = this.invoices;
			const length = pool.length;

			for ( let i = 0; i < length; i++ ) {
				const { invoiceId, customerId, recipient } = pool[i] || {};

				if ( String( invoiceId || "" ).replace( /\s+/g, " " ).toLowerCase().indexOf( query ) > -1 ) {
					this.selectedInvoice = invoiceId;
					return;
				}

				if ( String( customerId || "" ).replace( /\s+/g, " " ).toLowerCase().indexOf( query ) > -1 ) {
					customerCandidate = invoiceId;
				} else if ( String( recipient || "" ).replace( /\s+/g, " " ).toLowerCase().indexOf( query ) > -1 ) {
					customerCandidate = invoiceId;
				}
			}

			if ( customerCandidate ) {
				this.selectedInvoice = customerCandidate;
			}
		},
		shortcut( event ) {
			switch ( String( event.key || "" ).toLowerCase() ) {
				case "f" :
					this.$refs.invoices.focus();
					break;

				case "c" :
					this.$refs.customers.focus();
					break;

				case "b" :
					this.$refs.customers.focus();
					break;

				case "i" :
					this.$refs.me.querySelector( ".invoice-items" )?.focus();
					break;

				default :
					return;
			}

			event.preventDefault();
			event.stopPropagation();
		},
		assignNextInvoiceId() {
			this.setInvoiceId( adjustNumeric( this.latestSavedInvoiceId, 1, 1 ) );
		},
	},
	computed: {
		...mapState( {
			setupVisible: "setup/visible",
			vertical: "vertical",
			pdf: "pdf",
			globalError: "globalError",
		} ),
		...mapGetters( {
			hasStorage: "invoice/hasStorage",
			hasInvoiceId: "invoice/hasInvoiceId",
			latestSavedInvoiceId: "invoice/latestSavedInvoiceId",
			isSmallDevice: "device/isSmallDevice",
			isLeanDevice: "device/isLeanDevice",
		} ),
		hasSelection() {
			return this.hasStorage && ( this.selectedCustomerInvoice || this.selectedInvoice );
		},
		invoices() {
			const invoices = this.$store.state.invoice.saved.slice();

			invoices.sort( ( l, r ) => {
				const lid = String( l.invoiceId || "" ).trim();
				const rid = String( r.invoiceId || "" ).trim();

				return rid.localeCompare( lid );
			} );

			return invoices;
		},
		customers() {
			const customers = this.$store.getters["invoice/latestPerCustomer"].slice();

			customers.sort( ( l, r ) => String( r.invoiceId ).trim().localeCompare( String( l.invoiceId ).trim() ) );

			return customers;
		},
		invoicesOfCustomer() {
			if ( this.selectedCustomerInvoice ) {
				const ref = this.invoices.find( invoice => invoice.invoiceId === this.selectedCustomerInvoice );

				if ( ref ) {
					return this.invoices.filter( invoice => invoice.customerId === ref.customerId );
				}
			}

			return this.invoices;
		},
	},
};
</script>

<style lang="scss">
$brand_color: #c30045;
$dark_text: darken($brand_color, 30%);
$bright_text: white;

:root {
	--font-family: "Open Sans";
	--font-size: 12pt;
	--line-height: 1.36rem;
	--form-line-height: 2rem;

	--brand-color: #{$brand_color};

	--bright-background: white;
	--bright-text-color: #{$dark_text};
	--dark-background: #{darken( $brand_color, 15% )};
	--dark-text-color: #{$bright_text};

	--navbar-bg: var(--dark-background);
	--navbar-text: var(--dark-text-color);

	--touch-frame-width: 0.2rem;
	--touch-background-active: #{desaturate(lighten( $brand_color, 58%), 50%)};
	--touch-background-active-optional: #{darken(desaturate(lighten( $brand_color, 58%), 50%), 8%)};
	--touch-background-focused: #{desaturate(lighten( $brand_color, 53%), 60%)};
	--touch-background-inactive: #{lighten( $brand_color, 60%)};
	--touch-text-active: #{$dark_text};
	--touch-text-inactive: #{transparentize( $dark_text, 0.4 )};

	--bright-icon-background: var(--bright-background);
	--bright-icon-text: var(--bright-text-color);
	--bright-icon-focused: var(--touch-background-active);
	--bright-icon-set-background: var(--dark-background);
	--bright-icon-set-text: var(--dark-text-color);
	--bright-icon-set-focused: #{darken( $brand_color, 10% )};

	--dark-icon-background: transparent;
	--dark-icon-text: var(--dark-text-color);
	--dark-icon-focused: #ffffff40;
	--dark-icon-set-background: #{desaturate(lighten( $brand_color, 55%), 60%)};
	--dark-icon-set-text: var(--bright-text-color);
	--dark-icon-set-focused: #{desaturate(lighten( $brand_color, 50%), 60%)};

	--row-separator-color: #{desaturate(lighten( $brand_color, 50%), 70%)};

	--view-background: white;
	--header-background: #{desaturate(lighten( $brand_color, 38%), 70%)};
	--row-even-background: #{desaturate(lighten( $brand_color, 62%), 70%)};
	--row-odd-background: #{desaturate(lighten( $brand_color, 61%), 70%)};
}

html {
	position: relative;
	height: 100%;
	font: normal var(--font-size)/var(--line-height) var(--font-family);
	overscroll-behavior-y: none;
}

body {
	margin: 0;
	padding: 0;
	border: 0;
	display: flex;
	flex-flow: row nowrap;
	justify-content: stretch;
	align-items: stretch;
	position: relative;
	min-height: 100%;
	overscroll-behavior-y: none;
}

@media screen and (min-width: 640px) and (min-height:960px) {
	body {
		overflow: hidden;
	}
}
</style>

<style lang="scss" scoped>
#app {
	flex: 0 0 100%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: stretch;
	align-items: stretch;
}

#panel {
	flex: 0 0 auto;
	display: grid;
	grid-template-areas: "info storage tools";
	grid-gap: 1rem;
	vertical-align: center;
	background: var(--navbar-bg);
	color: var(--navbar-text);
	padding: 0.3rem 1rem;
	line-height: 2;

	> * {
		display: flex;
		flex-flow: row wrap;
	}

	a {
		color: white;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}

.info {
	grid-area: info;
	justify-content: flex-start;
	display: flex;
	flex-flow: row nowrap;
	gap: 0.2rem;

	.latest-invoice-id {
		display: inline-block;
		cursor: pointer;
	}
}

.storage {
	grid-area: storage;
	justify-content: center;
	display: flex;
	flex-flow: row nowrap;
	gap: 0.2rem;

	.group {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		gap: 0.2rem;
	}

	select {
		font: inherit;
		max-width: 10em;
		margin-right: 0.5em;

		option.prompt > span {
			color: #ddd;
		}
	}
}

.tools {
	grid-area: tools;
	justify-content: flex-end;
	display: flex;
	flex-flow: row nowrap;
	gap: 0.2rem;
}

.uploader {
	width: 0;
	height: 0;
	overflow: hidden;
}

.global-error-flag {
	display: block;
	color: white;
	cursor: help;

	&.inactive {
		opacity: 0.2;
	}
}

@media screen and (max-width: 1023.99px) {
	#panel {
		grid-template-areas: "info tools" "storage storage";
	}
}

@media screen and (max-width: 639.99px) {
	#panel {
		.storage > .group.selectors {
			white-space: normal;
			text-align: center;

			+ .group {
				margin-top: 1rem;
			}
		}

		button {
			min-width: 3rem;
		}
	}
}

@media (pointer: none) {
	.tools, .storage, .storage .group, .info {
		gap: 1rem;
	}
}
</style>
