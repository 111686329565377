<script>
import OptionsPicker from "../2nd-level/OptionsPicker.vue";

/**
 * Marks items by value and their inner sorting order to be provided atop all
 * other items.
 *
 * @type {Object<string,number>}
 */
const special = {
	DE: 1, AT: 2, CH: 3,
	FR: 4, GB: 4, DK: 4, NL: 4, BE: 4, LU: 4, PL: 4, SE: 4, CZ: 4, NO: 4, SK: 4,
};

export default {
	name: "CountrySelector",
	components: { OptionsPicker },
	data() {
		return {
			options: [],
		};
	},
	props: {
		disabled: Boolean,
		label: String,
		prompt: String,
		size: String,
		value: String,
		limitSize: Boolean,
	},
	async mounted() {
		const { daten: items } = await import( "../../../assets/lists/iso-3166-1-country-codes.json" );

		this.options = items
			.map( ( [ value, , label ] ) => ( { value, label, promoted: special[value] } ) )
			.sort( ( l, r ) => {
				if ( Boolean( l.promoted ) !== Boolean( r.promoted ) ) {
					return l.promoted ? -1 : 1;
				}

				return ( l.promoted ? l.promoted - r.promoted : 0 ) || l.label.localeCompare( r.label );
			} );
	}
};
</script>

<template>
	<OptionsPicker
		:label="label"
		:limit-size="limitSize"
		:prompt="prompt"
		:options="options"
		:value="value"
		:size="size"
		:disabled="disabled"
		@input="$emit( 'input', $event )"
	/>
</template>

<style scoped lang="scss">

</style>
