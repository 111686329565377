<script>
import OptionsPicker from "../2nd-level/OptionsPicker.vue";

/**
 * Marks items by value and their inner sorting order to be provided atop all
 * other items.
 *
 * @type {Object<string,number>}
 */
const special = {
	380: 1, 381: 2,
};

export default {
	name: "DocumentTypeSelector",
	components: { OptionsPicker },
	data() {
		return {
			options: [],
		};
	},
	props: {
		disabled: Boolean,
		label: String,
		prompt: String,
		size: String,
		value: [ Number, String ],
		limitSize: Boolean,
	},
	async mounted() {
		const { daten: items } = await import( "../../../assets/lists/untdid-1001-document-name-codes.json" );
		const { DocumentCodeType: localized } = await import( "../../../lib/x-rechnung" );

		this.options = items
			.map( ( [ value, label ] ) => ( {
				value: parseInt( value ),
				label: localized.get( parseInt( value ) ) ?? label,
				promoted: special[value]
			} ) )
			.sort( ( l, r ) => {
				if ( Boolean( l.promoted ) !== Boolean( r.promoted ) ) {
					return l.promoted ? -1 : 1;
				}

				return ( l.promoted ? l.promoted - r.promoted : 0 ) || l.label.localeCompare( r.label );
			} );
	}
};
</script>

<template>
	<OptionsPicker
		:label="label"
		:limit-size="limitSize"
		:prompt="prompt"
		:options="options"
		:value="value"
		:size="size"
		:disabled="disabled"
		@input="$emit( 'input', Number( $event ) )"
	/>
</template>

<style scoped lang="scss">

</style>
