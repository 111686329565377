import Vue from "vue";
import App from "./components/App.vue";
import router from "./router";
import store from "./store";
import faInstall from "./fontawesome";

import "./assets/fonts.css";

faInstall( Vue );

Vue.config.productionTip = false;

Vue.config.keyCodes = {
	n: 78,
	s: 83,
};


const lean = window.matchMedia( "screen and (max-width:1279.99px)" );
const leanHandler = event => {
	store.commit( event.matches ? "device/setLean" : "device/setWide" );
};
// eslint-disable-next-line no-unused-expressions
lean.addEventListener( "change", leanHandler );
leanHandler( lean );

const small = window.matchMedia( "screen and (max-width:639.99px), screen and (max-height: 899.99px)" );
const smallHandler = event => {
	store.commit( event.matches ? "device/setSmall" : "device/setLarge" );
};
// eslint-disable-next-line no-unused-expressions
small.addEventListener( "change", smallHandler );
smallHandler( small );


new Vue( {
	router,
	store,
	render: h => h( App )
} ).$mount( "#app" );
