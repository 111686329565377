<script>
import TextDate from "../form/2nd-level/TextDate.vue";
import TextField from "../form/2nd-level/TextField.vue";
import TextBlock from "../form/1st-level/TextBlock.vue";
import { mapState } from "vuex";

export default {
	name: "SimpleHeadEditor",
	components: { TextBlock, TextField, TextDate },
	computed: {
		...mapState( {
			invoiceId: state => state.invoice.invoiceId,
			customerId: state => state.invoice.customerId,
			billingDate: state => state.invoice.billingDate,
			deliveryDate: state => state.invoice.deliveryDate,
			preface: state => state.invoice.preface,
			postface: state => state.invoice.postface,
			recipient: state => state.invoice.recipient,
		} ),
	},
	methods: {
		track( action, value ) {
			this.$store.dispatch( `invoice/${action}`, value );
		},
	},
};
</script>

<template>
	<div class="fields">
		<TextField
			class="w50 number" prompt="Rechnungsnummer"
			case="upper"
			:value="invoiceId"
			@input="track('setInvoiceId',$event)"
		></TextField>
		<TextField
			class="w50 customer" prompt="Kundennummer"
			case="upper"
			:value="customerId"
			@input="track('setCustomerId',$event)"
		></TextField>
		<TextDate
			class="w50 billing"
			prompt="Rechnungsdatum"
			:value="billingDate"
			@input="track('setBillingDate',$event)"
		/>
		<TextDate
			class="w50 delivery"
			prompt="Lieferdatum"
			:value="deliveryDate"
			@input="track('setDeliveryDate',$event)"
		/>
		<TextBlock
			class="w100 address"
			prompt="Anschrift"
			:max-rows="5"
			:min-rows="5"
			:value="recipient"
			@input="track('setRecipient',$event)"
		/>
		<TextBlock
			class="w100 preface"
			prompt="Einleitung"
			:max-rows="5"
			:value="preface"
			@input="track('setPreface',$event)"
		/>
		<TextBlock
			class="w100 postface"
			prompt="Grußformel"
			:max-rows="5"
			:value="postface"
			@input="track('setPostface',$event)"
		/>
	</div>
</template>

<style scoped lang="scss">
.fields {
	display: grid;
	grid-template-areas:
		"address number customer"
		"address billing delivery"
		"preface postface postface";
	grid-template-columns: 2fr 1fr 1fr;
	grid-template-rows: auto 1fr auto;
	grid-gap: 0.5rem;
	padding: 1rem;
	position: relative;
	background: var(--header-background);

	> div {
		position: relative;
	}

	> .number {
		grid-area: number;
	}

	> .customer {
		grid-area: customer;
	}

	> .billing {
		grid-area: billing;
	}

	> .delivery {
		grid-area: delivery;
	}

	> .address {
		grid-area: address;
	}

	> .preface {
		grid-area: preface;
	}

	> .postface {
		grid-area: postface;
	}
}

@media screen and (max-width: 1023.99px) and (min-width: 640px) {
	.fields {
		grid-template-areas:
			"number customer"
			"billing delivery"
			"address address"
			"preface preface"
			"postface postface";
		grid-template-columns: 1fr 1fr;
	}
}

@media screen and (max-width: 639.99px) {
	.fields {
		grid-template-areas:
			"number"
			"customer"
			"billing"
			"delivery"
			"address"
			"preface"
			"postface";
		grid-template-columns: 1fr;
	}
}
</style>
