<template>
	<div class="invoice-items container">
		<PopOver
			v-if="toggleColumns"
			@cancel-popup="toggleColumns = false"
		>
			<template v-slot:header>Spaltenkonfiguration</template>
			<ColumnsEditor></ColumnsEditor>
		</PopOver>
		<div
			class="rows"
			@keydown.exact.alt.page-up.prevent.stop="addItem(-1, $event)"
			@keydown.exact.alt.page-down.prevent.stop="addItem(100000, $event)"
			@keydown.exact.alt.left.capture.prevent.stop="focusLeft($event)"
			@keydown.exact.alt.right.capture.prevent.stop="focusRight($event)"
			@keydown.exact.alt.up.capture.prevent.stop="focusUp($event)"
			@keydown.exact.alt.down.capture.prevent.stop="focusDown($event)"
		>
			<div class="row header">
				<div class="description" v-if="columns.description">Beschreibung</div>
				<div class="date" v-if="columns.date">Datum</div>
				<div class="amount" v-if="columns.amount">Menge</div>
				<div class="unit" v-if="columns.unit">Einheit</div>
				<div class="price" v-if="columns.price">Einzelpreis</div>
				<div class="discount" v-if="columns.discount">Rabatt</div>
				<div class="tax" v-if="columns.tax">Steueranteil</div>
				<div class="tools"><span>
					<FormButton
						flat title="Fügt eine Position am Anfang der Rechnung ein ..."
						@click="addItem(-1)"
					><fa :icon="['fas', 'plus']"></fa></FormButton>
					<FormButton
						flat class="columns" title="Spaltenkonfiguration"
						@click="toggleColumns = true"
					><fa :icon="['fas', 'table-columns']"></fa></FormButton>
					<FormButton
						flat
						disabled
					></FormButton>
					<FormButton
						flat title="Löscht die ausgewählten Position ..."
						@click="removeItem()"
						disabled
					><fa :icon="['fas', 'x']"></fa></FormButton>
				</span></div>
			</div>
			<div class="row" v-for="( item, index ) of items" :key="item.key"
				 @keydown.exact.shift.alt.up.capture.prevent.stop="moveUp(index, $event)"
				 @keydown.exact.shift.alt.down.capture.prevent.stop="moveDown(index, $event)"
				 @keydown.exact.alt.page-up.capture.prevent.stop="addItem(index - 1, $event, index)"
				 @keydown.exact.alt.page-down.capture.prevent.stop="addItem(index, $event)"
				 @keydown.exact.alt.delete.capture.prevent.stop="removeItem(index, $event)"
			>
				<div class="description" v-if="columns.description">
					<TextBlock prompt="Beschreibung" :value="item.description" :min-rows="2"
							   @input="setCell(index, 'description', $event)"></TextBlock>
				</div>
				<div class="date" v-if="columns.date">
					<TextDate
						:value="item.date"
						:disabled="!item.unit"
						@input="setCell(index,'date', $event)"
					/>
				</div>
				<div class="amount" v-if="columns.amount">
					<TextField
						prompt="Menge"
						align="right"
						post="x"
						size="2.2rem"
						:value="item.amount"
						:disabled="!item.unit || item.unit==='-'"
						@input="setCell(index, 'amount', $event)"
					/>
				</div>
				<div class="unit" v-if="columns.unit">
					<UnitSelector
						:value="item.unit"
						@input="setCell(index,'unit', $event)"
					/>
				</div>
				<div class="price" v-if="columns.price">
					<PriceInput
						prompt="Einzelpreis"
						:pre="item.unit === '-' ? '' : 'á'"
						size="3rem"
						:value="item.price"
						:disabled="!item.unit"
						@input="setCell(index, 'price', $event)"
					/>
				</div>
				<div class="discount" v-if="columns.discount">
					<DiscountInput
						prompt="Rabatt"
						:value="item.discount"
						size="2rem"
						:disabled="!item.unit"
						@input="setCell(index, 'discount', $event)"
					/>
				</div>
				<div class="tax" v-if="columns.tax">
					<TaxSelector
						size="7rem"
						:value="item.tax"
						:disabled="!item.unit"
						@input="setCell(index,'tax', $event)"
					/>
				</div>
				<div class="tools"><span>
					<FormButton
						flat
						title="Fügt eine Kopie der aktuellen Position unter dieser ein … (Alt+PageDn)"
						@click="addItem(index)"
					><fa :icon="['fas', 'plus']"></fa></FormButton>
					<FormButton
						flat
						title="Schiebt diese Position nach oben … (Shift+Alt+Up)"
						@click="moveUp(index)"
						:disabled="index === 0"
					><fa :icon="['fas', 'arrow-alt-up']"></fa></FormButton>
					<FormButton
						flat
						title="Schiebt diese Position nach unten … (Shift+Alt+Down)"
						@click="moveDown(index)"
						:disabled="index === items.length - 1"
					><fa :icon="['fas', 'arrow-alt-down']"></fa></FormButton>
					<FormButton
						flat
						title="Löscht diese Position … (Alt+Entf)"
						@click="removeItem(index)"
					><fa :icon="['fas', 'x']"></fa></FormButton>
				</span></div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import TextField from "../form/2nd-level/TextField";
import TextBlock from "../form/1st-level/TextBlock";
import FormButton from "../form/1st-level/FormButton";
import PopOver from "../container/PopOver.vue";
import ColumnsEditor from "./ColumnsEditor.vue";
import PriceInput from "../form/3rd-level/PriceInput.vue";
import TaxSelector from "../form/3rd-level/TaxSelector.vue";
import DiscountInput from "../form/3rd-level/DiscountInput.vue";
import UnitSelector from "../form/3rd-level/UnitSelector.vue";
import TextDate from "../form/2nd-level/TextDate.vue";
import { createNewItem } from "../../store/invoice";

const predecessor = "previousElementSibling";
const successor = "nextElementSibling";

/**
 * Gets zero-based index of row of invoice containing provided element.
 *
 * @param {HTMLElement} element DOM element assumed to be contained in a row representing an item of invoce
 * @returns {number} zero-based index of containing row
 */
const getRowIndex = element => {
	let index = -1;

	for ( let row = element.closest( ".row" ); row; row = row[predecessor] ) {
		if ( !row.classList.contains( "header" ) ) {
			index++;
		}
	}

	return index;
};

/**
 * Fetches DOM element representing item of invoice at given zero-based index
 * using some element contained in any row for reference.
 *
 * @param {HTMLElement} element element assumed to be contained in any row of invoice
 * @param {number} index zero-based index of row to fetch
 * @returns {HTMLElement|undefined} found DOM element representing selected row, undefined otherwise
 */
const getRowAtIndex = ( element, index ) => {
	let myIndex = getRowIndex( element );

	if ( myIndex >= -1 ) {
		let row = element?.closest( ".row" );

		if ( myIndex === index ) {
			return row;
		}

		const step = myIndex > index ? -1 : 1;
		const ref = myIndex > index ? predecessor : successor;

		for ( row = row[ref]; row; row = row[ref] ) {
			if ( !row.classList.contains( "header" ) ) {
				myIndex += step;

				if ( myIndex === index ) {
					return row;
				}
			}
		}

		return element?.closest( ".row" ).parentElement.lastElementChild;
	}

	return undefined;
};

/**
 * Finds containing element of provided one representing a column (or cell) in
 * a row of invoce.
 *
 * @param {HTMLElement} element element assumed to be contained in a column of some row
 * @returns {HTMLElement} found containing element representing single column/cell of its row
 */
const getColumn = element => {
	let column = element;

	for ( ; column && !column.parentElement.classList.contains( "row" ); column = column.parentElement ) {} // eslint-disable-line no-empty

	return column;
};

/**
 * Fetches a list of class names applied to an element containing provided one
 * as its column in a row of the invoice.
 *
 * @param {HTMLElement} element element to be contained in a row's column
 * @returns {string[]} sorted list of class names
 */
const getColumnClasses = element => getColumn( element )?.className?.split( /\s+/ ).sort( ( l, r ) => l.localeCompare( r ) );

/**
 * Creates CSS selector non-uniquely (!) matched by the column of a row
 * containing provided element.
 *
 * @param {HTMLElement} element element to be contained in a row's column
 * @returns {string} CSS selector non-uniquely matching that containing column
 */
const getColumnSelector = element => getColumnClasses( element )?.map( name => `.${name}` ).join( "" );

/**
 * Retrieves element of DOM representing particular column in a selected row.
 *
 * @param {HTMLElement} rows element containing rows of invoice
 * @param {number} rowIndex zero-based index of row to deliver
 * @param {string} columnSelector CSS selector matched by desired column of selected row
 * @returns {HTMLElement|undefined} found element representing selected column, undefined otherwise
 */
const getColumnAtRow = ( rows, rowIndex, columnSelector ) => {
	const row = getRowAtIndex( rows.querySelector( ".row" ), rowIndex );

	for ( let iter = row?.firstElementChild; iter; iter = iter[successor] ) {
		if ( iter.matches( columnSelector ) ) {
			return iter;
		}
	}

	return undefined;
};

/**
 * Tries to focus input control in selected column.
 *
 * @param {HTMLElement} column element representing single column/cell in a row of invoice
 * @param {string} field CSS selector suitable for selecting the input control in that column
 * @returns {undefined|boolean} true if control has been found, is active and has been focused,
 *          false if control has been found but hasn't been focused,
 *          undefined if control hasn't been found
 */
const focusField = ( column, field = "input,textarea,select" ) => {
	const target = column?.querySelector( field );

	if ( target ) {
		if ( target.disabled ) {
			return false;
		}

		target.focus();

		try {
			target.setSelectionRange?.( 0, 100000 );
		} catch {} // eslint-disable-line no-empty

		return true;
	}

	return undefined;
};

/**
 * Focuses input control in row/column containing provided element or selectable
 * via provided row index and column CSS selector.
 *
 * @param {HTMLElement} element element contained in a row's column
 * @param {number} rowIndex zero-based index of row to use instead of provided element's row
 * @param {string} column CSS selector matching column to use instead of provided element's column
 * @returns {void}
 */
const refocusField = ( element, rowIndex = undefined, column = undefined ) => {
	if ( element ) {
		const rows = element.closest( ".row" ).parentElement;

		rowIndex ??= getRowIndex( element ); 		// eslint-disable-line no-param-reassign
		column ??= getColumnSelector( element );	// eslint-disable-line no-param-reassign

		setTimeout( () => {
			const cell = getColumnAtRow( rows, rowIndex, column );

			cell?.querySelector( "input,textarea,select" )?.focus();
		} );
	}
};

export default {
	name: "ItemsEditor",
	components: {
		TextDate,
		TextField,
		UnitSelector,
		DiscountInput,
		TaxSelector, PriceInput, ColumnsEditor, PopOver, FormButton, TextBlock,
	},
	data() {
		return {
			toggleColumns: false,
		};
	},
	computed: {
		...mapState( {
			items: state => state.invoice.items,
		} ),
		...mapGetters( {
			columns: "config/columns",
		} ),
	},
	methods: {
		...mapActions( {
			storeAddItem: "invoice/addItem",
			storeMoveItem: "invoice/moveItem",
			storeRemoveItem: "invoice/removeItem",
			storeSetItemProperty: "invoice/setItemProperty",
		} ),
		setCell( index, property, value ) {
			this.storeSetItemProperty( { index, property, value } );
			this.$emit( "input", this.items );
		},
		addItem( index, event = undefined, srcIndex = undefined ) {
			const item = this.items[srcIndex ?? index] || createNewItem();

			this.storeAddItem( { ...item, index: index + 1 } );
			this.$emit( "input", this.items );

			refocusField( event?.target, index + 1 );
		},
		removeItem( index, event ) {
			if ( confirm( `Soll der Eintrag #${index + 1} wirklich gelöscht werden?` ) ) {
				this.storeRemoveItem( index );

				if ( this.items.length < 1 ) {
					this.storeAddItem();
				}

				this.$emit( "input", this.items );

				refocusField( event?.target, index );
			}
		},
		moveUp( index, event ) {
			this.storeMoveItem( { index, delta: -1 } );
			this.$emit( "input", this.items );

			refocusField( event?.target, index - 1 );
		},
		moveDown( index, event ) {
			this.storeMoveItem( { index, delta: +1 } );
			this.$emit( "input", this.items );

			refocusField( event?.target, index + 1 );
		},
		focusLeft( event ) {
			for ( let column = getColumn( event.target )?.[predecessor]; column; column = column[predecessor] ) {
				if ( focusField( column ) ) {
					return;
				}
			}
		},
		focusRight( event ) {
			for ( let column = getColumn( event.target )?.[successor]; column; column = column[successor] ) {
				if ( focusField( column ) ) {
					return;
				}
			}
		},
		focusUp( event ) {
			const selector = getColumnSelector( event.target );

			for ( let row = event.target.closest( ".row" )?.[predecessor]; row; row = row[predecessor] ) {
				for ( let column = row.firstElementChild; column; column = column[successor] ) {
					if ( column.matches( selector ) ) {
						if ( focusField( column, event.target.nodeName ) ) {
							return;
						}

						break;
					}
				}
			}
		},
		focusDown( event ) {
			const selector = getColumnSelector( event.target );

			for ( let row = event.target.closest( ".row" )?.[successor]; row; row = row[successor] ) {
				for ( let column = row.firstElementChild; column; column = column[successor] ) {
					if ( column.matches( selector ) ) {
						if ( focusField( column, event.target.nodeName ) ) {
							return;
						}

						break;
					}
				}
			}
		},
	},
	created() {
		if ( this.items.length < 1 ) {
			this.storeAddItem();
		}
	}
};
</script>

<style scoped lang="scss">
.container {
	> .rows {
		width: 100%;

		> .header {
			position: sticky;
			top: 0;
			z-index: 1;
			background: white;
			box-shadow: 0 0 3px #0003;

			> :not(.tools) {
				font-size: 0.7em;
				text-align: center;
				vertical-align: middle;
				line-height: 2;
			}
		}
	}

	.row {
		&:nth-child(2n+1) {
			background: var(--row-odd-background);
		}

		&:nth-child(2n) {
			background: var(--row-even-background);
		}

		> div {
			font: inherit;
			line-height: 2rem;
		}

		> .tools {
			> span {
				display: inline-flex;
				flex-flow: row nowrap;
				justify-content: flex-end;
				align-items: center;
			}
		}
	}
}

@media print, screen and (min-width: 1024px) {
	.container {
		overflow: auto;
		max-height: 100%;

		> .rows {
			display: table;
			width: 100%;
		}

		.row {
			display: table-row;
			vertical-align: top;
			padding: 0.5rem 1em;

			> div {
				display: table-cell;
				vertical-align: top;
				padding: 0.5rem 0.2rem;
				border-bottom: 1px solid var(--row-separator-color);

				&:first-child {
					padding-left: 1rem;
				}

				&:last-child {
					padding-right: 1rem;
				}
			}

			> .date {
				width: 1px;
			}

			> .amount {
				width: 1px;
			}

			> .price {
				width: 1px;
			}

			> .unit {
				width: 1px;
			}

			> .discount {
				width: 1px;
			}

			> .tax {
				width: 1px;
			}

			> .tools {
				width: 1px;
			}
		}
	}
}

@media screen and (max-width: 1023.99px) {
	.row {
		display: grid;
		grid-template-rows: auto 1fr;
		grid-gap: 0.5rem 1rem;
		vertical-align: top;
		padding: 1rem;
		border-bottom: 1px solid var(--row-separator-color);

		&.header {
			grid-gap: 0 1rem;
			padding: 0.2rem 1rem;
		}

		> div {
			vertical-align: top;
		}

		> .description {
			grid-area: description;
		}

		> .amount {
			grid-area: date;
		}

		> .amount {
			grid-area: amount;
		}

		> .price {
			grid-area: price;
		}

		> .unit {
			grid-area: unit;
		}

		> .discount {
			grid-area: discount;
		}

		> .tax {
			grid-area: tax;
		}

		> .tools {
			grid-area: tools;
			text-align: right;

			> span > button {
				padding-left: 1rem;
				padding-right: 1rem;
			}
		}

		:deep(select) {
			width: 100%;
		}
	}
}

@media screen and (max-width: 1023.99px) and (min-width: 640px) {
	.row {
		grid-template-areas: "description description description tools tools tools" "amount date unit price discount tax";
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	}
}

@media screen and (max-width: 639.99px) {
	.row {
		grid-template-areas: "description description description" "date amount unit" "price discount tax" "tools tools tools";
		grid-template-columns: 1fr 1fr 1fr;
	}
}
</style>
