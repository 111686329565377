<script>
import { mapState } from "vuex";
import TextDate from "../form/2nd-level/TextDate.vue";
import TextField from "../form/2nd-level/TextField.vue";
import FlexFields from "../container/FlexFields.vue";
import CountrySelector from "../form/3rd-level/CountrySelector.vue";
import DocumentTypeSelector from "../form/3rd-level/DocumentTypeSelector.vue";

// https://xeinkauf.de/xrechnung/versionen-und-bundles/?hilite=xrechnung&highlight=%22xrechnung%22

export default {
	name: "ExtendedHeadEditor",
	components: { DocumentTypeSelector, CountrySelector, FlexFields, TextField, TextDate },
	computed: {
		...mapState( {
			invoice: state => state.invoice,
		} ),
	},
	methods: {
		update( field, value ) {
			const action = "set" + field[0].toUpperCase() + field.substring( 1 );

			this.$store.dispatch( `invoice/${action}`, value );
		},
	},
};
</script>

<template>
	<FlexFields class="fields">
		<DocumentTypeSelector
			limit-size
			class="w25"
			label="Dokumentenart (BT-3)"
			:value="invoice.invoiceType"
			@input="update('invoiceType',$event)"
		></DocumentTypeSelector>
		<TextField
			case="upper"
			class="w25"
			label="Rechnungsnummer (BT-1)"
			:value="invoice.invoiceId"
			@input="update('invoiceId',$event)"
		></TextField>
		<TextField
			case="upper"
			class="w25"
			label="vorherige Rechnungsnummer (BT-25)"
			optional
			prompt="optional"
			:value="invoice.precedingInvoiceReference"
			@input="update('precedingInvoiceReference',$event)"
		></TextField>
		<TextField
			case="upper"
			class="w25"
			label="Auftragsnummer (BT-14)"
			optional
			prompt="optional"
			:value="invoice.salesOrderReference"
			@input="update('salesOrderReference',$event)"
		></TextField>
		<TextDate
			class="w25"
			label="Rechnungsdatum (BT-2)"
			:value="invoice.billingDate"
			@input="update('billingDate',$event)"
		/>
		<TextDate
			class="w25"
			label="Lieferdatum (BT-72)"
			optional
			:value="invoice.deliveryDate"
			@input="update('deliveryDate',$event)"
		/>
		<TextDate
			class="w25"
			label="Abrechnungszeitraum von (BT-73)"
			optional
			:value="invoice.invoicingPeriodStartDate"
			@input="update('invoicingPeriodStartDate',$event)"
		/>
		<TextDate
			class="w25"
			label="Abrechnungszeitraum bis (BT-74)"
			optional
			:value="invoice.invoicingPeriodEndDate"
			@input="update('invoicingPeriodEndDate',$event)"
		/>
		<h3 class="w100">Kunde</h3>
		<TextField
			case="upper"
			class="w25"
			label="Kundennummer (BT-46)"
			prompt="eigene Kundennummer"
			:value="invoice.customerId"
			@input="update('customerId',$event)"
		></TextField>
		<TextField
			case="upper"
			class="w25"
			label="Kundenreferenz / Leitweg-ID (BT-10)"
			prompt="wird vom Kunden bereitgestellt"
			:value="invoice.buyerReference"
			@input="update('buyerReference',$event)"
		></TextField>
		<TextField
			class="w25"
			label="E-Mail (BT-49)"
			prompt="Empfänger der Rechnung ..."
			type="email"
			:value="invoice.buyerElectronicAddress"
			@input="update('buyerElectronicAddress',$event)"
		></TextField>
		<TextField
			class="w25"
			label="Name (BT-44)"
			prompt="Name, Firma, ..."
			:value="invoice.buyerName"
			@input="update('buyerName',$event)"
		></TextField>
		<TextField
			class="w50"
			label="Anschrift (BT-50)"
			prompt="Straße und Hausnummer"
			:value="invoice.buyerAddress1"
			@input="update('buyerAddress1',$event)"
		></TextField>
		<TextField
			class="w50"
			label="Anschrift (BT-51)"
			prompt="Ergänzungen"
			:value="invoice.buyerAddress2"
			@input="update('buyerAddress2',$event)"
		></TextField>
		<TextField
			class="w25"
			label="PLZ (BT-53)"
			:value="invoice.buyerPostCode"
			@input="update('buyerPostCode',$event)"
		></TextField>
		<TextField
			class="w25"
			label="Ort (BT-52)"
			:value="invoice.buyerCity"
			@input="update('buyerCity',$event)"
		></TextField>
		<CountrySelector
			limit-size
			class="w25"
			label="Land (BT-55)"
			:value="invoice.buyerCountry"
			@input="update('buyerCountry',$event)"
		></CountrySelector>
		<TextField
			class="w25"
			label="Region, Bundesland (BT-54)"
			prompt="optional"
			optional
			:value="invoice.buyerCountrySubdivision"
			@input="update('buyerCountrySubdivision',$event)"
		></TextField>
	</FlexFields>
</template>

<style scoped lang="scss">
.fields {
	background: var(--header-background);
	position: relative;
	z-index: 2;
	padding: 1em;

	h3 {
		margin: 0;
	}
}
</style>
