<template>
	<div class="field" :class="{disabled, labelled: label, optional}">
		<label v-if="label && name" :for="name">{{ label }}:</label>
		<label v-else-if="label">{{ label }}:</label>
		<label v-if="pre" class="pre">{{ pre }}</label>
		<TextInput
			v-if="name"
			:class="['align-' + align]"
			:disabled="disabled"
			:id="name"
			:placeholder="prompt"
			:style="style"
			:type="type"
			:value="validated"
			@input="onchange"
		></TextInput>
		<TextInput
			v-if="!name"
			:class="['align-' + align]"
			:disabled="disabled"
			:placeholder="prompt"
			:style="style"
			:type="type"
			:value="validated"
			@input="onchange"
		></TextInput>
		<label v-if="post" class="post">{{ post }}</label>
	</div>
</template>

<script>
import TextInput from "../1st-level/TextInput";

export default {
	name: "TextField",
	components: { TextInput },
	props: {
		align: {
			type: String,
			default: "left",
		},
		case: String,
		format: String,
		disabled: Boolean,
		label: String,
		name: String,
		optional: Boolean,
		pattern: RegExp,
		post: String,
		pre: String,
		prompt: String,
		size: String,
		type: {
			type: String,
			default: "text",
		},
		trim: Boolean,
		value: [ String, Number, Date ],
	},
	data() {
		return {
			current: this.value,
		};
	},
	computed: {
		style() {
			return this.size ? { width: this.size } : null;
		},
		validated() {
		  return this.validate( this.value );
		},
	},
	methods: {
		validate( value ) {
			let filtered = value == null ? "" : String( value );

			if ( this.trim ) {
				filtered = filtered.trim();
			}

			switch ( this.case ) {
				case "upper" :
					filtered = filtered.toUpperCase();
					break;

				case "lower" :
					filtered = filtered.toLowerCase();
					break;
			}

			switch ( String( this.format ).toLowerCase() ) {
				case "numeric" :
					filtered = filtered.replace( /\D/g, "" );
					break;

				case "alpha" :
					filtered = filtered.replace( /\W/g, "" );
					break;
			}

			if ( this.regexp ) {
				filtered = filtered.match( this.regexp );
				if ( Array.isArray( filtered ) ) {
					filtered = filtered.join( "" );
				}
			}

			return filtered;
		},
		onchange( value ) {
			this.$emit( "input", value );
		},
	},
};
</script>

<style scoped lang="scss">
	.field {
		position: relative;
		display: flex;
		flex-flow: row nowrap;
		align-items: flex-start;
		justify-content: stretch;

		&.disabled {
			visibility: hidden;
		}

		&.labelled {
			margin-top: 0.7em;

			> label {
				position: absolute;
				bottom: 100%;
				left: 0;
				font-size: 0.7em;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 100%;
			}
		}

		&.optional input {
			background: var(--touch-background-active-optional);
		}
	}

	input {
		flex: 1000 1 auto;

		&.align-right {
			text-align: right;
		}
	}

	.pre {
		flex: 1 1 auto;
		padding: 0.2rem 0.3rem 0.2rem 0;
		text-align: right;
	}

	.post {
		flex: 1 1 auto;
		padding: 0.2rem 0 0.2rem 0.3rem;
	}
</style>
