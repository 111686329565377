import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faSave,
	faFolderOpen,
	faTrashAlt,
	faFileAlt,
	faDownload,
	faUpload,
	faSyncAlt,
	faFilePdf,
	faBorderCenterV,
	faBorderCenterH,
	faDraftingCompass,
	faBug, faExternalLink
} from "@fortawesome/pro-duotone-svg-icons";
import {
	faTrashAlt as faSolidTrashAlt,
	faArrowAltUp,
	faArrowAltDown,
	faPlus,
	faTimes,
	faArrowAltRight,
	faTableColumns,
	faX,
	faCheck,
	faCrosshairs,
	faDownLeftAndUpRightToCenter,
	faFileXml,
	faTriangleExclamation
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/**
 * Configures fontawesome for use in application.
 *
 * @param {object} Vue instance of Vue to use
 * @returns {void}
 */
export default function faInstall( Vue ) {
	library.add( faSave );
	library.add( faFolderOpen );
	library.add( faTrashAlt );
	library.add( faFileAlt );
	library.add( faDownload );
	library.add( faUpload );
	library.add( faSyncAlt );
	library.add( faFilePdf );
	library.add( faBorderCenterV );
	library.add( faBorderCenterH );
	library.add( faDraftingCompass );

	library.add( faSolidTrashAlt );
	library.add( faArrowAltDown );
	library.add( faArrowAltUp );
	library.add( faPlus );
	library.add( faBug );
	library.add( faTimes );
	library.add( faArrowAltRight );
	library.add( faExternalLink );
	library.add( faTableColumns );
	library.add( faCheck );
	library.add( faX );
	library.add( faCrosshairs );
	library.add( faDownLeftAndUpRightToCenter );
	library.add( faFileXml );
	library.add( faTriangleExclamation );

	Vue.component( "fa", FontAwesomeIcon ); // eslint-disable-line vue/multi-word-component-names
}
