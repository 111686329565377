<script>
import TextField from "../2nd-level/TextField.vue";
import { parseNumber } from "../../../lib/data";

export default {
	name: "NumericInput",
	components: { TextField },
	props: {
		value: [ String, Number ],
		pre: String,
		prompt: String,
		post: String,
		size: {
			type: String,
			default: "5rem"
		},
		disabled: Boolean,
	},
	computed: {
		invalid() {
			return isNaN( parseNumber( this.value ) );
		},
	},
};
</script>

<template>
  <TextField
      align="right"
	  :class="{invalid}"
      :size="size"
      :prompt="prompt"
      :value="value"
      :pre="pre"
      :post="post"
      :disabled="disabled"
      @input="$emit('input', $event)"
  ></TextField>
</template>

<style scoped lang="scss">
.invalid :deep(input) {
	color: red;
}
</style>
