import Vue from "vue";
import VueRouter from "vue-router";
import InvoiceEditorView from "../components/view/InvoiceEditorView.vue";

Vue.use( VueRouter );

const routes = [
	{
		path: "/",
		name: "home",
		redirect: "/editor",
	},
	{
		path: "/editor",
		name: "editor",
		component: InvoiceEditorView
	},
];

const router = new VueRouter( {
	mode: "history",
	base: process.env.BASE_URL,
	routes
} );

export default router;
